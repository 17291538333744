import React from 'react';
import { observer } from 'mobx-react';
import { Backdrop, CircularProgress, withStyles, Dialog, DialogContent, Box, DialogContentText, DialogActions, Button, IconButton } from '@material-ui/core';
import { liveUpdateStore } from './LiveUpdateStore';
import Alert from '@material-ui/lab/Alert';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';

export const StyledBackdrop = withStyles((theme) => {
  return {
    root: {
      zIndex: theme.zIndex.drawer + 1000,
      color: '#fff',
    },
  };
})(Backdrop);

@observer
export class SocketConnectionModal extends React.Component<{}> {

  public render() {
    const isReconnecting = liveUpdateStore.isReconnecting;
    const isDisconnected = liveUpdateStore.isDisconnected;
    if (isDisconnected) {
      return this.renderDisconnectedModal();
    }
    if (isReconnecting) {
      return this.renderReconnectingBackdrop();
    }
    return <></>;
  }

  private renderReconnectingBackdrop() {
    return (
      <StyledBackdrop open={true} >
        <Box>
          <Box>
            <CircularProgress thickness={5}
              style={{ color: 'white', display: 'block', margin: 'auto' }} />
          </Box>
          <Alert severity="warning" variant="filled" style={{ marginTop: '35px' }}>
            Die Lead-Datenbank wird aktualisiert.
          </Alert>
        </Box>
      </StyledBackdrop>
    );
  }

  private renderDisconnectedModal() {
    return (
      <Dialog open={true}>
        <DialogContent>
          <DialogContentText>
            <IconButton disabled={true} style={{ backgroundColor: 'rgba(142, 0, 56, 0.1)' }}>
              <SyncProblemIcon color="primary" fontSize="large" />
            </IconButton>
          </DialogContentText>
          <DialogContentText>
            <Box fontWeight="fontWeightBold" m={2}
              component="span" display="block" maxWidth="330px">
              Die Verbindung zum Server ist unterbrochen
            </Box>
          </DialogContentText>
          <DialogActions>
            <Button onClick={this.reloadApp}
              style={{ minWidth: 160 }}
              variant="outlined" color="primary">
              Seite Neuladen
              </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }

  private reloadApp() {
    window.location.reload();
  }
}
