import React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';

const StyledTextArea = (props: TextFieldProps) => (
  <TextField
    inputProps={{ style: { padding: 15, marginBottom: 20 } }}
    InputLabelProps={{ style: { fontSize: 14 } }}
    FormHelperTextProps={{ style: { right: '15px', bottom: '6px', position: 'absolute' } }}
    {...props}
  />
);

interface IProps {
  onUpdate: (newValue: string) => void;
  maxLength: number;
  value: string;
}

export class TextAreaWithCounter extends React.Component<IProps & TextFieldProps> {
  public render() {
    const { value, maxLength, onUpdate, ...rest } = this.props;

    return (
      <StyledTextArea
        multiline
        fullWidth
        helperText={`${value.length}/${maxLength}`}
        value={value}
        onChange={(e) => this.sanitizedUpdate(e.target.value)}
        {...rest}
      />
    );
  }

  private sanitizedUpdate(value: string) {
    const maxLength = this.props.maxLength;
    const sanitizedValue = value.length > maxLength
      ? value.substring(0, maxLength) : value;

    this.props.onUpdate(sanitizedValue);
  }
}
