import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Box,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { assureLockLeadPopupStore } from './AssureLockLeadPopupStore';
import { ExpiredStatus } from '@ems/ems-lwl-models';
import { leadLockManager } from '../lead-locks/LeadLockManager';
import CloseIcon from '@material-ui/icons/Close';
import labels from './AssureLockLeadPopupConstants';

@observer
export class AssureLockLeadPopup extends React.Component<{}> {

  public render() {
    return (
      <Dialog
        open={assureLockLeadPopupStore.isAreYouSurePopupVisible()}
        onClose={() => this.handleClose(false)}>
        <DialogContent>
          <IconButton aria-label="close" onClick={() => this.handleClose(false)}
            style={{ position: 'absolute', top: '10px', right: '10px' }}>
            <CloseIcon />
          </IconButton>
          <DialogContentText id="alert-dialog-description">
            <Box fontWeight="fontWeightBold" m={1} component="span" display="block">
              {this.getPopupHeader()}
            </Box>
            <Box component="span" display="block">
              {labels.SIND_SIE_SICHER}
            </Box>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose(false)}
            variant="outlined" color="primary">
            {labels.NEIN}
            </Button>
          <Button onClick={() => this.handleClose(true)}
            variant="contained" color="primary" autoFocus>
            {labels.JA}
            </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private getPopupHeader(): string | undefined {
    if (assureLockLeadPopupStore.areYouSurePopupLead) {
      const expiredStatus: ExpiredStatus =
        assureLockLeadPopupStore.areYouSurePopupLead.status.expiredStatus;
      switch (expiredStatus) {
        case ExpiredStatus.FINISHED:
          return labels.STATUS_ERLEDIGT;
        case ExpiredStatus.NO:
          return labels.STATUS_NEIN;
        case ExpiredStatus.NO_WITH_TERMIN:
          return labels.STATUS_NEIN_TERMIN;
      }
    }
  }

  private handleClose = (isPositive: boolean) => {
    if (isPositive && assureLockLeadPopupStore.areYouSurePopupLead) {
      leadLockManager.lockLead(assureLockLeadPopupStore.areYouSurePopupLead)
        .then(() => assureLockLeadPopupStore.closeAreYouSurePopup());
    } else {
      assureLockLeadPopupStore.closeAreYouSurePopup();
    }
  }
}
