import { observable, action, computed } from 'mobx';
import { popupStore } from '../popup/PopupStore';
import { UserModel, DealershipModel, SalesChannel } from '@ems/ems-lwl-models';
import { newOfferPopupManager } from './NewOfferPopupManager';
import { QueryString } from '@nova/uri';
import { coopPartnerStore } from '../coop-partners/store/CoopPartnerStore';
import { salesChannelStore } from '../sales-channels/store/SalesChannelStore';

const popupName = 'NewOfferPopup';

class NewOfferPopupStore {
  @observable public selectedCoopPartnerName = '';
  @observable public selectedDealerUser: UserModel | null = null;
  @observable public selectedDealership: DealershipModel | null = null;
  @observable public selectedSalesChannelValue = '';

  @observable public dealerUsers: UserModel[] = [];
  @observable public dealerships: DealershipModel[] = [];
  @observable public salesChannels: SalesChannel[] = [];

  public isDialogOpened(): boolean {
    return popupStore.popupVisible === popupName;
  }

  public isValid(): boolean {
    return (
      !!this.selectedCoopPartnerName && !!this.selectedSalesChannelValue
    );
  }

  public showModal() {
    popupStore.requestOpen(popupName);
    this.handleSalesNetworkChange(salesChannelStore.getDefaultSalesChannel.value);
  }

  @computed
  public get redirectURL(): string {
    const params = {
      dealerEmployeeNumber: this.selectedDealerUser?.employeeNumber,
      dealership: this.selectedDealership?.dealershipId,
      salesChannel: this.selectedSalesChannelValue,
      organization: this.selectedCoopPartnerName,
    };

    const queryString = QueryString.toString(params);
    const coopPartner = coopPartnerStore.partners.find(
      (item) =>
        item.value === this.selectedCoopPartnerName,
    );

    const baseURL = coopPartner?.url.trim();
    return baseURL ? `${baseURL}/vehiclesearch${queryString}` :'';
  }

  @action
  public closeModal() {
    if (!this.isDialogOpened()) {
      return;
    }
    this.clearStore();
    popupStore.requestClose(popupName);
  }

  @action
  private clearStore() {
    this.selectedCoopPartnerName = '';
    this.selectedDealerUser = null;
    this.selectedDealership = null;
    this.selectedSalesChannelValue = '';
    this.dealerUsers = [];
    this.dealerships = [];
    this.salesChannels = [];
  }

  @action
  public handleCoopPartnerChange = async (coopPartnerName: string) => {
    if (!coopPartnerName) return;
    this.selectedCoopPartnerName = coopPartnerName;
    this.selectedDealerUser = null;
    this.selectedDealership = null;
    await newOfferPopupManager.fetchAllBy(coopPartnerName);
  }

  @action
  public handleDealerUserChange = async (dealerUser: UserModel | null) => {
    this.selectedDealerUser = dealerUser;
    if (dealerUser && dealerUser.dealership) {
      await newOfferPopupManager.fetchDealerships({ dealershipId: dealerUser.dealership });

      const dealerUserDealership = this.dealerships[0];

      // cover edge case for Volvo dealerUsers
      if (dealerUserDealership) {
        this.handleDealershipChange(dealerUserDealership);
      } else {
        this.selectedDealership = null;
      }

    } else if (this.selectedCoopPartnerName) {
      this.selectedDealership = null;
      await newOfferPopupManager.fetchAllBy(this.selectedCoopPartnerName);
    }
  }

  @action
  public handleDealershipChange = async (dealership: DealershipModel | null) => {
    this.selectedDealership = dealership;
    if (dealership && dealership.dealershipId) {
      await newOfferPopupManager.fetchDealerUsers({ dealership: dealership.dealershipId });
    } else if (this.selectedCoopPartnerName) {
      this.selectedDealerUser = null;
      await newOfferPopupManager.fetchAllBy(this.selectedCoopPartnerName);
    }
  }

  @action
  public handleSalesNetworkChange(salesChannel: string) {
    this.selectedSalesChannelValue = salesChannel;
  }

  @action
  public setManualUser(employeeNumber: string) {
    if (employeeNumber.length > 30) return;
    const user: UserModel = {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      organization: '',
      dealership: '',
      roleName: '',
      employeeNumber,
    };
    this.selectedDealerUser = user;
  }
}

export const newOfferPopupStore = new NewOfferPopupStore();
