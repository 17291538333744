import { DealershipModel, UserModel } from '@ems/ems-lwl-models';
import { Button, Dialog, DialogActions, DialogContent, FormControl, FormHelperText, Grid, MenuItem, TextField } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { observer } from 'mobx-react';
import React, { ChangeEvent } from 'react';
import { coopPartnerStore } from '../coop-partners/store/CoopPartnerStore';
import { salesChannelStore } from '../sales-channels/store/SalesChannelStore';
import NewOfferPopupHeader from './components/NewOfferPopupHeader';
import { newOfferPopupStore } from './NewOfferPopupStore';

const ResponsiveDialogContent = withStyles((theme) => {
  return {
    root: {
      width: '450px',
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
      },
    },
  };
})(DialogContent);

@observer
export class NewOfferPopup extends React.Component {

  private handleCoopPartnerChange = async (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    await newOfferPopupStore.handleCoopPartnerChange(e.target.value);
  }

  private handleDealerUserChange = async (
    e: ChangeEvent<{}>,
    dealerUser: UserModel | null,
  ) => {
    await newOfferPopupStore.handleDealerUserChange(dealerUser);
  }

  private handleManualEnteredUserDealer = (e: ChangeEvent<HTMLInputElement>) => {
    const employeeNumber = e.target.value.trim();
    newOfferPopupStore.setManualUser(employeeNumber);
  }

  private handleDealershipChange = async (
    e: ChangeEvent<{}>,
    dealership: DealershipModel | null,
  ) => {
    await newOfferPopupStore.handleDealershipChange(dealership);
  }

  private handleSalesNetworkChange(e: ChangeEvent<HTMLInputElement>) {
    newOfferPopupStore.handleSalesNetworkChange(e.target.value);
  }

  private handleRedirectAction = () => {
    const url = newOfferPopupStore.redirectURL;
    window.open(url, '_blank');
  }

  private renderDealerUserInputStandard = () => {
    return (<Autocomplete
      id="userDealerComboBox"
      options={Array.from(newOfferPopupStore.dealerUsers)}
      getOptionLabel={(option) =>
        `${option.employeeNumber} - ${option.firstName} ${option.lastName}`
      }
      onChange={this.handleDealerUserChange}
      value={newOfferPopupStore.selectedDealerUser}
      getOptionSelected={(option, value) => (option.id === value.id)}
      noOptionsText="Keine Ergebnisse gefunden"
      renderInput={(params) => (
        <TextField
          {...params}
          id="userDealer"
          name="userDealer"
          label="Verkäufernummer"
        />
      )}
    />
    );
  }

  private renderDealerUserInputBMW = () => {
    return (<TextField
      fullWidth
      id="userDealer"
      name="userDealer"
      label="Verkäufernummer"
      value={newOfferPopupStore.selectedDealerUser?.employeeNumber}
      onChange={this.handleManualEnteredUserDealer}
    />
    );
  }

  public render() {
    return (
      <Dialog open={newOfferPopupStore.isDialogOpened()} onClose={() => { }}>
        <NewOfferPopupHeader />
        <ResponsiveDialogContent>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  id="coopPartner"
                  name="coopPartner"
                  value={newOfferPopupStore.selectedCoopPartnerName}
                  label="Kooperationspartner"
                  onChange={this.handleCoopPartnerChange}
                  select
                  aria-describedby="coop-partner-required"
                >
                  {coopPartnerStore.partners.map((partner) => (
                    <MenuItem key={partner.value} value={partner.value}>{partner.label}</MenuItem>
                  ))}
                </TextField>
                <FormHelperText
                  id="coop-partner-required"
                  style={{ position: 'absolute', top: 0, right: 0 }}
                >
                  * erforderlich
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item>
              {newOfferPopupStore.selectedCoopPartnerName === 'BMW' ?
                (this.renderDealerUserInputBMW()) : (this.renderDealerUserInputStandard())}
            </Grid>
            <Grid item>
              <Autocomplete
                id="dealershipComboBox"
                options={Array.from(newOfferPopupStore.dealerships)}
                getOptionLabel={(option) =>
                  `${option.dealershipId} - ${option.dealershipName}`
                }
                onChange={this.handleDealershipChange}
                value={newOfferPopupStore.selectedDealership}
                getOptionSelected={(option, value) => (option.dealershipId === value.dealershipId)}
                noOptionsText="Keine Ergebnisse gefunden"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="dealership"
                    name="dealership"
                    label="Autohaus"
                  />
                )}
              />
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <TextField
                  id="salesChannel"
                  name="salesChannel"
                  label="Vertriebsweg"
                  onChange={this.handleSalesNetworkChange}
                  value={newOfferPopupStore.selectedSalesChannelValue}
                  select
                  fullWidth={true}
                  aria-describedby="sales-channel-required"
                >
                  {salesChannelStore.salesChannels.map((channel) => (
                    <MenuItem key={channel.value} value={channel.value}>{channel.label}</MenuItem>
                  ))}
                </TextField>
                <FormHelperText
                  id="sales-channel-required"
                  style={{ position: 'absolute', top: 0, right: 0 }}
                >
                  * erforderlich
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </ResponsiveDialogContent>
        <DialogActions style={{ marginTop: 20 }}>
          <Button
            onClick={() => newOfferPopupStore.closeModal()}
            variant="outlined"
            color="primary"
            style={{ minWidth: 150 }}
          >
            Zurück
          </Button>
          <Button
            onClick={() => this.handleRedirectAction()}
            disabled={!newOfferPopupStore.isValid()}
            variant="contained"
            color="primary"
            autoFocus
            style={{ minWidth: 150 }}
          >
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

}
