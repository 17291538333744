import React, { FunctionComponent } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Divider } from '@material-ui/core';

const StyledDivider = withStyles({
  vertical: {
    width: '2px',
    height: '32px',
    backgroundColor: '#ffffff',
  },
})(Divider);

const VerticalDivider: FunctionComponent<{}> = () => {
  return (<StyledDivider orientation="vertical" variant="fullWidth"/>);
};

export default VerticalDivider;
