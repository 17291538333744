import React from 'react';
import { TableCell, TableSortLabel, Tooltip, TableCellProps } from '@material-ui/core';
import { SortDirection } from '@ems/ems-lwl-models';

interface SortableHeaderCellProps {
  id?: string;
  label?: string;
  sortBy: string | null;
  sortDirection: SortDirection | null;
  onClick: ((sortId?: string) => void);
  align?: TableCellProps['align'];
  sortable?: boolean;
  children: React.ReactElement<any, any> | string;
}

export function SortableHeaderCell(props: SortableHeaderCellProps) {
  const { id, label, sortBy, sortDirection, onClick, align, sortable, children } = props;
  const labelToShow = label || (typeof children === 'string' && children);

  if (!sortable) {
    return <TableCell align={align}>
      <Tooltip title={labelToShow} placement="bottom">
        <div style={{ cursor: 'pointer' }}>
          {children}
        </div>
      </Tooltip>
    </TableCell>;
  }

  return (
      <TableCell align={align}>
        <Tooltip title={labelToShow} placement="bottom">
          <TableSortLabel
              active={id === sortBy}
              direction={getSortDirection(sortBy, sortDirection)}
              onClick={() => onClick(id)}
              hideSortIcon={true}>
            {children}
          </TableSortLabel>
        </Tooltip>
      </TableCell>
  );
}

function getSortDirection(sortBy: string | null,
                          sortDirection: SortDirection | null): 'asc' | 'desc' | undefined {
  if (!sortBy || !sortDirection) {
    return;
  }
  switch (sortDirection) {
    case SortDirection.ASC:
      return 'asc';
    case SortDirection.DESC:
      return 'desc';
  }
}
