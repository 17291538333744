import { authClient } from '../../clients/auth-client/AuthClient';
import { resetPasswordStore } from './ResetPasswordStore';
import { notificationStore } from '../notification/NotificationStore';
import { logger } from '../logger/Logger';

class ResetPasswordManager {
  public async resetPassword(email: string): Promise<void> {
    if (resetPasswordStore.loading) {
      return;
    }
    resetPasswordStore.clear();
    resetPasswordStore.loading = true;
    try {
      const response = await authClient.resetPassword(email);
      notificationStore.showSuccess(response.data.message);
    } catch (err) {
      logger.error(
        `An error occurred while reseting password for given email address: ${email}.`,
      );
      resetPasswordStore.error = true;
      throw err;
    } finally {
      resetPasswordStore.loading = false;
    }
  }
}

export const resetPasswordManager = new ResetPasswordManager();
