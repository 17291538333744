import { observer } from 'mobx-react';
import React, { ChangeEvent, Component } from 'react';
import { TextField, Box, Button, CircularProgress } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { restorePasswordManager } from './RestorePasswordManager';
import { restorePasswordStore } from './RestorePasswordStore';
import { RuleCheck } from './RuleCheck';

const ENTER_KEY_CODE = 13;

const TransparentButton = withStyles({
  root: {
    borderStyle: 'solid',
    border: '2px solid white',
    background: 'transparent',
    '&:hover': {
      background: 'rgba(99, 0, 39, 0.5)',
    },
  },
})(Button);

const RestorePasswordFormControlsBox = withStyles((theme) => {
  return {
    root: {
      width: '500px',
      marginLeft: '200px',
      marginTop: '355px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        margin: '45% auto',
      },
    },
  };
})(Box);

interface RestorePasswordFormProps {
  token: string;
}
interface RestorePasswordFormState {
  email: string;
  password: string;
  structure: any;
  buttonDisabled: boolean;
  token: string;
}

@observer
export class RestorePasswordForm extends Component<
  RestorePasswordFormProps,
  RestorePasswordFormState
> {
  constructor(props: RestorePasswordFormState) {
    super(props);

    this.state = {
      email: '',
      password: '',
      structure: null,
      buttonDisabled: true,
      token: this.props.token,
    };
  }

  public async componentDidMount() {
    const result = await restorePasswordManager.getSchema();
    this.setState({
      structure: result?.structure.passwordManagement,
    });
  }

  public render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isError = restorePasswordStore.loading; //  DO NOT REMOVE - needed by mobx

    return (
      <Box
        height="100%"
        alignItems="flex-start"
        display="flex"
        top="0"
        marginTop="-80px"
        minHeight="100vh"
      >
        <Box width="100%">
          <RestorePasswordFormControlsBox>
            <form noValidate autoComplete="off">
              <Box fontSize={28} color="white" mb={4}>
                {this.state.structure?.header.mainTextRestore}
              </Box>
              <Box mt={2}>
                <TextField
                  id="email"
                  name="email"
                  value={this.state.email}
                  label={this.state.structure?.fields.email.label}
                  onChange={this.handleEmailChange}
                  type="text"
                  onKeyUp={this.handleRestorePassOnEnter}
                  fullWidth={true}
                  InputLabelProps={{
                    style: { color: 'white' },
                  }}
                />
              </Box>
              <Box mt={2}>
                <TextField
                  id="password"
                  name="password"
                  value={this.state.password}
                  label={this.state.structure?.fields.password.label}
                  onChange={this.handlePasswordChange}
                  fullWidth={true}
                  type="password"
                  onKeyUp={this.handleRestorePassOnEnter}
                  InputLabelProps={{
                    style: { color: 'white' },
                  }}
                />
              </Box>
              {restorePasswordStore.error && this.displayError()}
              {this.ruleCheckers(this.state.structure?.rule)}
              {this.ruleCheckerHelpers(
                this.state.structure?.fields.newPassword.helperTextArray,
              )}
              <Box mt={2}>
                <TransparentButton
                  variant="contained"
                  color="primary"
                  onClick={this.handlePasswordRestore}
                  fullWidth={true}
                  disabled={
                    restorePasswordStore.loading || this.state.buttonDisabled
                  }
                  type="button"
                  style={{ color: 'white' }}
                >
                  {restorePasswordStore.loading ? (
                    <CircularProgress
                      style={{ color: 'white', width: '24px', height: '24px' }}
                    />
                  ) : this.state.structure ? (
                    this.state.structure.buttons.submit.label
                  ) : (
                    ''
                  )}
                </TransparentButton>
              </Box>
            </form>
          </RestorePasswordFormControlsBox>
        </Box>
      </Box>
    );
  }

  private ruleCheckers(rules: any) {
    if (!rules) return null;
    const mapItem: any = [];
    Object.keys(rules).map((key) =>
      mapItem.push(
        <RuleCheck
          text={rules[key].text}
          valid={this.ruleTest(rules[key].reg)}
          helper={false}
          key={`passwordRule-${key}`}
        />,
      ),
    );

    return mapItem;
  }

  private ruleCheckerHelpers(helpers: Array<string>) {
    if (!helpers) return null;
    let i = 0;
    const mapItem: any = [];
    for (const text of helpers) {
      mapItem.push(
        <RuleCheck
          text={text}
          valid={true}
          helper={true}
          key={`passwordRuleHelper-${i}`}
        />,
      );
      i = i + 1;
    }
    return mapItem;
  }

  private ruleTest(rule: string) {
    return !!this.state.password.match(new RegExp(rule));
  }

  private displayError() {
    return (
      <Box mt={2} color="#F44335" fontWeight="bold">
        E-Mail oder Passwort falsch
      </Box>
    );
  }

  private handleRestorePassOnEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === ENTER_KEY_CODE) {
      this.handlePasswordRestore();
      e.preventDefault();
    }
  }

  private handleEmailChange = (e: ChangeEvent<HTMLInputElement>) =>
    this.setState({ email: e.target.value })

  private handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const rule = this.state.structure?.rule;
    const buttonDisabled = Object.keys(rule).every((key) =>
      value.match(new RegExp(rule[key].reg)),
    );
    this.setState({
      password: value,
      buttonDisabled: !buttonDisabled,
    });
  }

  private handlePasswordRestore = async () => {
    try {
      await restorePasswordManager.restorePassword(
        this.state.email,
        this.state.password,
        this.state.token,
      );
    } catch (err) {
      this.setState({
        email: '',
        password: '',
      });
    }
  }
}
