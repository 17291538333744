import { observable } from 'mobx';
import { LeadModel, StatusValue } from '@ems/ems-lwl-models';
import { apiClient } from '../../clients/api-client/api.client';
import { logger } from '../logger/Logger';
import { popupStore } from '../popup/PopupStore';
import { notificationStore } from '../notification/NotificationStore';

const popupName = 'leadUpdateModal';

class LeadUpdateModalStore {

  @observable public editedLead: LeadModel | null = null;
  @observable public isNoteLoading: boolean = false;
  @observable public isSaving: boolean = false;
  @observable public note: string = '';
  @observable public status: StatusValue | null = null;
  @observable public dueDate: Date | null = null;
  @observable public dueDateTime: Date | null = null;
  @observable public dueDateValid: boolean = true;
  @observable public dueDateTimeValid: boolean = true;

  public isDialogOpened() {
    return popupStore.popupVisible === popupName;
  }

  public showModal(lead: LeadModel) {
    this.isNoteLoading = true;
    this.editedLead = lead;
    this.status = lead.status.lastStatus;
    this.dueDate = lead.status.dueDate;
    this.dueDateTime = lead.status.dueDate;
    popupStore.requestOpen(popupName);
    this.loadNote(lead.id);
  }

  public closeModal() {
    popupStore.requestClose(popupName);
  }

  private async loadNote(leadId: number): Promise<void> {
    try {
      const note = await apiClient.getNoteTextForLead(leadId);
      this.note = note;
    } catch (err) {
      logger.error('Fehler beim Laden der Bemerkung', err, leadId);
      this.closeModal();
      notificationStore.showError('Fehler beim Laden der Bemerkung');
    } finally {
      this.isNoteLoading = false;
    }
  }

  public updateDueDate(date?: Date) {
    if (!date) {
      return;
    }

    this.dueDate = date;
  }

  public updateDueDateTime(date?: Date) {
    if (!date) {
      return;
    }
    this.dueDateTime = date;
  }

  public isValid() {
    return this.dueDateValid && this.dueDateTimeValid;
  }
}

export const leadUpdateModalStore = new LeadUpdateModalStore();
