import { observable, computed } from 'mobx';
import { Moment, isMoment } from 'moment';

type ErrorInput = string | number | object | Date | Moment | null | undefined;

function isInputCorrect(value: ErrorInput): boolean {
  if (value === null || value === undefined) {
    return true;
  }

  if (!isMoment(value)) {
    throw new Error('Error handling for non-Moment providers is not supported');
  }

  return value.isValid();
}

class FilterErrorStore {
  @observable public isCreatedFromValid: boolean = true;
  @observable public isCreatedToValid: boolean = true;
  @observable public isTerminFromValid: boolean = true;
  @observable public isTerminToValid: boolean = true;

  public setCreatedFrom(value: ErrorInput) {
    this.isCreatedFromValid = isInputCorrect(value);
  }

  public setCreatedTo(value: ErrorInput) {
    this.isCreatedToValid = isInputCorrect(value);
  }

  public setTerminFrom(value: ErrorInput) {
    this.isTerminFromValid = isInputCorrect(value);
  }

  public setTerminTo(value: ErrorInput) {
    this.isTerminToValid = isInputCorrect(value);
  }

  @computed
  public get areFiltersCorrect() {
    return this.isCreatedFromValid &&
      this.isCreatedToValid &&
      this.isTerminFromValid &&
      this.isTerminToValid;
  }
}

export const filterErrorStore = new FilterErrorStore();
