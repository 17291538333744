import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Box,
  CircularProgress,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { notePopupStore } from './NotePopupStore';
import { leadLockManager } from '../../lead-locks/LeadLockManager';
import CloseIcon from '@material-ui/icons/Close';
import { TextAreaWithCounter } from '../../../components/TextAreaWithCounter';
import { MAX_NOTE_LENGTH } from '../NoteConstants';

@observer
export class NotePopup extends React.Component<{}> {

  public render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const noteValue = notePopupStore.note; // DO NOT DELETE - Needed by MobX
    return (
      <Dialog open={notePopupStore.isDialogOpened()} onClose={this.close}>
        {this.isLoading ? this.renderLoadingContent() : this.renderDialogContent()}
      </Dialog>
    );
  }

  private renderLoadingContent() {
    return (
      <DialogContent>
        <Box m={10} display="block">
          <CircularProgress />
        </Box>
      </DialogContent>
    );
  }

  private renderDialogContent() {
    const noteValue = notePopupStore.note;
    const isSavingInProgress = notePopupStore.isSaving;
    return (
      <>
        <DialogContent>
          <IconButton aria-label="close" onClick={this.close}
            style={{ position: 'absolute', top: '10px', right: '10px' }}>
            <CloseIcon />
          </IconButton>
          <DialogContentText>
            <Box fontWeight="fontWeightBold" m={2} component="span" display="block">
              Verkäufer Bemerkung
            </Box>
            <Box m={1} component="span" display="block" maxWidth="500px">
              Hier können Sie die Bemerkungen des Verkäufers einsehen und nach Belieben bearbeiten.
            </Box>
          </DialogContentText>
          <TextAreaWithCounter
            label="Bemerkung"
            placeholder={this.isReadonly() ? '' : 'Kommentar eingeben'}
            rows={5}
            rowsMax={5}
            disabled={this.isReadonly()}
            value={noteValue}
            onUpdate={(val) => notePopupStore.note = val}
            maxLength={MAX_NOTE_LENGTH}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close}
            style={{ minWidth: 160 }}
            variant="outlined" color="primary">
            Zurück
          </Button>
          {
            this.isReadonly() ? <></> : this.renderSaveButton(isSavingInProgress)
          }
        </DialogActions>
      </>
    );
  }

  private renderSaveButton = (isSavingInProgress: boolean) => {
    return (
      <Button onClick={this.saveChanges}
        style={{ minWidth: 160 }}
        variant="contained" color="primary"
        autoFocus
        disabled={this.isReadonly() || isSavingInProgress}>
        {isSavingInProgress
          ? <CircularProgress style={{ width: 24, height: 24 }} />
          : 'Speichern'
        }
      </Button>
    );
  }

  private get lead() {
    return notePopupStore.notePopupLead;
  }

  private isReadonly = () => {
    return this.lead === null || !leadLockManager.isLockedByMe(this.lead);
  }

  private get isLoading() {
    return notePopupStore.isNoteLoading;
  }

  private saveChanges = () => {
    if (!this.lead) {
      return;
    }
    notePopupStore.saveNote();
  }

  private close = () => {
    notePopupStore.closeNotePopup();
  }
}
