import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Box,
  TextField,
  CircularProgress,
  Typography,
  withStyles,
  MenuItem,
  Grid,
  Link,
  DialogTitle,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import CloseIcon from '@material-ui/icons/Close';
import { leadUpdateModalStore } from './LeadUpdateModalStore';
import { leadUpdateService } from './LeadUpdateService';
import DescriptionIcon from '@material-ui/icons/Description';
import { StatusValue } from '@ems/ems-lwl-models';
import { isEnum, ObjectKeys } from '@nova/typings';
import { logger } from '../logger/Logger';
import { statusesManager } from '../statuses/StatusesManager';
import { DatePicker } from '../../components/DatePicker';
import { TimePicker } from '../../components/TimePicker';
import InfoRounded from '@material-ui/icons/InfoOutlined';
import { TextAreaWithCounter } from '../../components/TextAreaWithCounter';
import { MAX_NOTE_LENGTH } from '../note/NoteConstants';
import { offerRedirectionService } from '../offer-redirection/offer-redirection.service';

const BoldText = withStyles({
  root: {
    fontWeight: 'bold',
    fontSize: 14,
  },
})(Typography);

const BolderText = withStyles({
  root: {
    fontWeight: 'bolder',
    fontSize: 16,
  },
})(Typography);

const ResponsiveDialogContent = withStyles(theme => {
  return {
    root: {
      width: '500px',
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
      },
    },
  };
},
)(DialogContent);

@observer
export class LeadUpdateModal extends React.Component<{}> {

  public render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const noteValue = leadUpdateModalStore.note; // DO NOT DELETE - Needed by MobX
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isValid = leadUpdateModalStore.isValid; // DO NOT DELETE - Needed by MobX
    return (
      <Dialog open={leadUpdateModalStore.isDialogOpened()} onClose={this.close} scroll="paper">
        {this.isLoading ? this.renderLoadingContent() : this.renderDialogContent()}
      </Dialog>
    );
  }

  private renderLoadingContent() {
    return (
      <ResponsiveDialogContent>
        <Box m={10} display="block">
          <CircularProgress />
        </Box>
      </ResponsiveDialogContent>
    );
  }

  private renderDialogContent() {
    return (
      <>
        {this.renderHeader()}
        <ResponsiveDialogContent dividers>
          {this.renderLeadInfo()}
          {this.renderInputs()}
        </ResponsiveDialogContent>
        {this.renderActions()}
      </>
    );
  }

  private renderHeader = () => {
    return (
      <DialogTitle>
        <IconButton aria-label="close" onClick={this.close}
          style={{ position: 'absolute', top: '10px', right: '10px' }}>
          <CloseIcon />
        </IconButton>
        <DialogContentText >
          <Box
            fontWeight="fontWeightBold" m={2} component="span" display="block">
            Nächster Anrufversuch
          </Box>
        </DialogContentText>
      </DialogTitle>
    );
  }

  private renderLeadInfo = () => {
    return (
      <>
        <table>
          <tbody>
            <tr>
              <td style={{ minWidth: 160 }}>
                <BoldText>Verkäufer ID</BoldText>
              </td>
              <td><Typography>{this.getDealerInfo()}</Typography></td>
            </tr>
            <tr>
              <td><BoldText>AnV</BoldText></td>
              <td><Typography>{this.lead?.status.contactAttempts}</Typography></td>
            </tr>
            <tr>
              <td><BoldText>Kunde</BoldText></td>
              <td><Typography>{this.getCustomerInfo()}</Typography></td>
            </tr>
            <tr>
              <td><BoldText>Telefonnummer</BoldText></td>
              <td><BolderText>{this.lead?.customer.phone || ''}</BolderText></td>
            </tr>
            <tr>
              <td><BoldText>Ang-Nr.</BoldText></td>
              <td><Typography>{this.lead?.offer.code}</Typography></td>
            </tr>
          </tbody>
        </table>
        <Box marginTop={3}>
          <Grid container>
            <Grid item >
              <DescriptionIcon color="primary" />
            </Grid>
            <Grid item >
              <BoldText color="primary">
                <Link href={this.getRedirectUrl()} color="inherit" target="_blank">
                  Angebot bearbeiten</Link>
              </BoldText>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }

  private getRedirectUrl() {
    if (!this.lead) {
      return '';
    }
    return offerRedirectionService.getRedirectUrl(this.lead.offer);
  }

  private getDealerInfo = () => {
    const firstName = this.noNullShow(this.lead?.dealer.firstName);
    const lastName = this.noNullShow(this.lead?.dealer.lastName);
    const organization = this.noNullShow(this.lead?.dealer.organization);

    if (firstName === '' && lastName === '' && organization === '') {
      return '';
    }
    return `${firstName} ${lastName}, ${organization}`;
  }

  private getCustomerInfo = () => {
    const firstName = this.noNullShow(this.lead?.customer.firstName);
    const lastName = this.noNullShow(this.lead?.customer.lastName);
    if (firstName === '' && lastName === '') {
      return '';
    }
    return `${firstName} ${lastName}`;
  }

  private renderInputs = () => {
    return (
      <>
        {this.lastStatusRenderer()}
        {this.renderTerminInputs()}
        {this.renderNoteInput()}
      </>
    );
  }

  private lastStatusRenderer = () => {
    const isLoading = leadUpdateModalStore.isSaving;
    const lastStatus = leadUpdateModalStore.status;

    return (
      <TextField
        label="Letztes Ergebnis"
        value={lastStatus}
        onChange={(event) => this.changeStatusOfLead(event.target.value)}
        select
        fullWidth={true}
        disabled={isLoading}
        style={{ marginTop: 12 }}
      >
        {this.renderStatusFilterOptions()}
      </TextField>
    );
  }

  private changeStatusOfLead = (newStatus: string) => {
    if (isEnum(StatusValue, newStatus)) {
      leadUpdateModalStore.status = newStatus;
    } else {
      logger.warn('Wrong status', newStatus);
    }
  }

  private renderStatusFilterOptions = () => {
    const automaticStatuses = [
      StatusValue.new, StatusValue.finished,
      StatusValue.terminatedByAdmin, StatusValue.done_maxAttempts,
    ];
    const options = ObjectKeys(StatusValue)
      .map(key => {
        const value = StatusValue[key];
        const shouldBeAvailable = automaticStatuses.indexOf(StatusValue[key]) < 0;
        const label = statusesManager.translateStatus(value);

        if (shouldBeAvailable) {
          return <MenuItem key={value} value={value}>{label}</MenuItem>;
        }
        return <MenuItem style={{ display: 'none' }} key={value} value={value}>{label}</MenuItem>;
      });

    return [
      ...options,
    ];
  }

  private renderTerminInputs = () => {
    const dueDate = leadUpdateModalStore.dueDate;
    const dueDateTime = leadUpdateModalStore.dueDateTime;

    return (
      <Grid container spacing={3} style={{ marginTop: 6 }}>
        <Grid item xs={6}>
          <DatePicker
            id="dueDate"
            name="DueDate"
            value={dueDate}
            label="Tag"
            onChange={(arg) => leadUpdateModalStore.updateDueDate(arg?.toDate())}
            onError={(error) => { leadUpdateModalStore.dueDateValid = !error; }}
          />
        </Grid>
        <Grid item xs={6}>
          <TimePicker
            label="Zeit"
            value={dueDateTime}
            onChange={(arg) => leadUpdateModalStore.updateDueDateTime(arg?.toDate())}
            onError={(error) => { leadUpdateModalStore.dueDateTimeValid = !error; }}
          />
        </Grid>
      </Grid>
    );
  }

  private renderNoteInput = () => {
    const noteValue = leadUpdateModalStore.note;

    return (
      <TextAreaWithCounter
        label="Bemerkung"
        placeholder="Kommentar eingeben"
        rows={5}
        rowsMax={5}
        value={noteValue}
        onUpdate={(val) => leadUpdateModalStore.note = val}
        style={{ marginTop: 12 }}
        maxLength={MAX_NOTE_LENGTH}
      />
    );
  }

  private renderActions = () => {
    const isSavingInProgress = leadUpdateModalStore.isSaving;
    const isValid = leadUpdateModalStore.isValid();

    return (<>
      <DialogActions style={{ justifyContent: 'left', padding: '8px 8px 8px 24px' }}>
        {this.renderFooterInfo()}
      </DialogActions>
      <DialogActions>
        <Button onClick={this.close}
          style={{ minWidth: 160 }}
          variant="outlined" color="primary">
          Zurück
        </Button>
        <Button onClick={this.saveChanges}
          style={{ minWidth: 160 }}
          variant="contained" color="primary"
          autoFocus
          disabled={isSavingInProgress || !isValid}>
          {isSavingInProgress
            ? <CircularProgress style={{ width: 24, height: 24 }} />
            : 'Speichern'
          }
        </Button>
      </DialogActions></>
    );
  }

  private renderFooterInfo = () => {
    return (
      <Typography color="textSecondary" style={{ fontSize: 12 }}>
        <InfoRounded style={{ fontSize: 12, marginRight: 5 }} />
        Der Status kann nach dem Speichern nie mehr bearbeiten werden
      </Typography>
    );
  }

  private get lead() {
    return leadUpdateModalStore.editedLead;
  }

  private get isLoading() {
    return leadUpdateModalStore.isNoteLoading;
  }

  private saveChanges = () => {
    leadUpdateService.saveChanges();
  }

  private close = () => {
    leadUpdateService.discardChanges();
  }

  private noNullShow = (value: string | null | undefined): string => {
    if (value) {
      return value.toString();
    }
    return '';
  }
}
