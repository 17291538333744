import { observable, action } from 'mobx';
import { LeadModel } from '@ems/ems-lwl-models';
import { popupStore } from '../popup/PopupStore';

const popupName = 'TerminPopup';

class TerminPopupStore {
  @observable public lead: LeadModel | null = null;
  @observable public dueDate: Date | null = null;
  @observable public dueDateTime: Date | null = null;
  @observable public isSaving: boolean = false;
  @observable public dueDateValid: boolean = true;
  @observable public dueDateTimeValid: boolean = true;

  public isDialogOpened() {
    return popupStore.popupVisible === popupName;
  }

  public isValid() {
    return this.dueDateValid && this.dueDateTimeValid;
  }

  @action
  public openModal(lead: LeadModel) {
    this.lead = lead;
    this.dueDate = lead.status.dueDate;
    this.dueDateTime = lead.status.dueDate;
    popupStore.requestOpen(popupName);
  }

  @action
  public closeModal() {
    if (!this.isDialogOpened()) {
      return;
    }

    this.lead = null;
    this.dueDate = null;
    this.dueDateTime = null;
    popupStore.requestClose(popupName);
  }

  public updateDueDate(date?: Date) {
    if (!date) {
      return;
    }

    this.dueDate = date;
  }

  public updateDueDateTime(date?: Date) {
    if (!date) {
      return;
    }
    this.dueDateTime = date;
  }
}

export const terminPopupStore = new TerminPopupStore();
