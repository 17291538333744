import { ThemeOptions } from '@material-ui/core';

export const leadsTableThemeOptions: ThemeOptions = {
  overrides: {
    MuiTableCell: {
      head: {
        color: '#666666',
        fontSize: '12px',
        letterSpacing: '0',
        lineHeight: '12px',
      },
      body: {
        color: '#333333',
        fontSize: '13px',
        letterSpacing: '0',
        lineHeight: '17px',
        verticalAlign: 'top',
      },
    },
    MuiChip: {
      root: {
        height: 'auto',
      },
      label: {
        fontSize: '12px',
        padding: '1px 10px',
      },
    },
    MuiCheckbox: {
      root: {
        padding: '0px',
      },
    },
    MuiTablePagination: {
      root: {
        color: '#757575',
      },
      caption: {
        fontSize: '12px',
      },
      select: {
        fontSize: '12px',
      },
    },
    MuiInput: {
      root: {
        marginTop: '0px !important',
      },
      input: {
        border: '1px solid rgba(0, 0, 0, 0.42)',
        paddingLeft: '6px',
        paddingRight: '6px',
        color: 'black',
        '&:focus': {
          borderColor: '#8E0038',
        },
        backgroundColor: 'white',
        fontSize: '13px',
        lineHeight: '24px',
        paddingTop: '1px',
        paddingBottom: '1px',
      },
      underline: {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
    MuiSelect: {
      root: {
        background: 'white !important',
      },
    },
  },

};
