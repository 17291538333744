import React from 'react';
import {
  createMuiTheme,
  ThemeProvider,
  CssBaseline,
} from '@material-ui/core';
import { Routes } from './routes/Routes';
import { BrowserRouter, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { emsThemeOptions } from './modules/theme/theme';
import TopBar from './modules/top-bar/TopBar';
import 'moment/locale/de';
import { loginUserManager } from './modules/login/LoginUserManager';
import { Login } from './pages/Login';
import { ResetPassword } from './pages/ResetPassword';
import { appStore } from './modules/app-store/store/AppStore';
import { loginStore } from './modules/login/LoginStore';
import { observer } from 'mobx-react';
import { createBrowserHistory } from 'history';

@observer
export default class App extends React.Component {

  public async componentDidMount() {
    try {
      await loginUserManager.checkUserLoggedIn();
    } catch (err) {}
  }

  public render() {
    const defaultTheme = createMuiTheme(emsThemeOptions);
    return (
      <ThemeProvider theme={defaultTheme}>
        <MuiPickersUtilsProvider locale="de" utils={MomentUtils}>
          <CssBaseline />
          <BrowserRouter>
            <ScrollToTop>
              <TopBar/>
              {this.renderRouter()}
            </ScrollToTop>
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    );
  }

  private renderRouter() {
    const currentPath = createBrowserHistory().location.pathname;
    const permitAll = ['/reset-password', '/restorepassword/'];
    if (appStore.isUserAuthorised === null) {
      // todo page-loading-🔔s-and-whistles
      return;
    }
    if (!appStore.isUserAuthorised && loginStore.passwordResetMail) {
      return <Route exact path="*" component={ResetPassword}/>;
    }
    if (appStore.isUserAuthorised || permitAll.some((path) => currentPath.includes(path))) {
      return <Routes/>;
    }
    return <Route exact path="*" component={Login}/>;
  }
}
