import { WebRestClient } from '@nova/web-rest-client';
import { config } from '../../config';
import { cookieHelper } from './CookieHelper';
import { UserModel } from '@ems/ems-lwl-models';

class AuthClient extends WebRestClient {

  constructor() {
    super(config.apiUrl);
  }

  private XRF_COOKIE_NAME = 'DCIL-csrf-token';
  private XRF_TOKEN_HEADER_NAME = 'x-csrf-token';

  /**
   * Used only to get a cookie with XRF token
   *
   * Cookie name: "***DCIL-csrf-token***"
   */
  public async fetchScheme(): Promise<any> {
    // This endpoint goes straight to meta-service
    return await this.httpClient.get('/meta-service/schema');
  }

  public async login(username: string, password: string): Promise<void> {
    const xrfToken = this.getXrfToken();
    // This endpoint goes straight to user-manager
    await this.httpClient.post(
      '/user-manager/user/login',
      {
        username,
        password,
      },
      {
        withCredentials: true,
        headers: {
          [this.XRF_TOKEN_HEADER_NAME]: xrfToken,
        },
      },
    );
  }

  public async logout(): Promise<void> {
    return await this.httpClient.post('/logout');
  }

  public async getCurrentUserInfo(): Promise<UserModel> {
    const response = await this.httpClient.get('/user/me');
    return response.data;
  }

  public async resetPassword(email: string): Promise<any> {
    const xrfToken = this.getXrfToken();
    // This endpoint goes straight to user-manager
    return await this.httpClient.post(
      '/user-manager/user/resetPassword',
      {
        email,
        organization: 'LWL',
      },
      {
        headers: {
          [this.XRF_TOKEN_HEADER_NAME]: xrfToken,
        },
      },
    );
  }

  public async restorePassword(email: string, password: string, token: string) {
    const xrfToken = this.getXrfToken();
    // This endpoint goes straight to user-manager
    return await this.httpClient.post(
      '/user-manager/user/setUserPassword',
      {
        email,
        password,
        token,
      },
      {
        headers: {
          [this.XRF_TOKEN_HEADER_NAME]: xrfToken,
        },
      },
    );
  }

  private getXrfToken(): string {
    const xrfCookie = cookieHelper.getValueByName(this.XRF_COOKIE_NAME);
    return xrfCookie;
  }
}

export const authClient = new AuthClient();
// TODO - only for debugging
(window as any).authClient = authClient;
