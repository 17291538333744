import { observable, computed } from 'mobx';
import { SalesChannel } from '@ems/ems-lwl-models';

class SalesChannelStore {

  @observable
  public salesChannels: SalesChannel[] = [];
  @observable
  public areSalesChannelsLoading = false;
  @computed
  public get areSalesChannelsReady(): boolean {
    return !this.areSalesChannelsLoading && this.salesChannels.length > 0;
  }
  @computed
  public get getDefaultSalesChannel(): SalesChannel {
    if (this.areSalesChannelsReady) {
      return this.salesChannels[this.salesChannels.length - 1];
    }
    return { value: '', label: '' };
  }

}

export const salesChannelStore = new SalesChannelStore();
