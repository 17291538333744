import { ThemeOptions } from '@material-ui/core';
import { datepickerThemeOptions } from './datepickerTheme';
import { timepickerThemeOptions } from './timepickerTheme';
import deepmerge from 'deepmerge';

const ERROR_COLOR = '#E80C26';

export const emsThemeOptions: ThemeOptions = deepmerge(
  timepickerThemeOptions,
  deepmerge<ThemeOptions>(
  datepickerThemeOptions, {
    palette: {
      primary: {
        main: '#8E0038',
      },
      error: {
        main: ERROR_COLOR,
      },
      background: {
        default: '#fff',
      },
      text: {
        primary: '#333333',
      },
    },
    typography: {
      fontFamily: 'Open Sans',
      h4: {
        fontWeight: 300,
        fontSize: '32px',
        lineHeight: '43px',
      },
      button: {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    props: {
      MuiInputLabel: {
        shrink: true,
      },
      MuiTextField: {
        variant: 'standard',
      },
    },
    overrides: {
      MuiInputLabel: {
        root: {
          fontWeight: 'bold',
          color: 'black',
          fontSize: '14px',
          lineHeight: '24px',
        },
      },
      MuiInput: {
        root: {
          marginTop: '20px !important',
        },
        input: {
          border: '1px solid rgba(0, 0, 0, 0.42)',
          paddingLeft: '6px',
          paddingRight: '6px',
          color: 'black',
          '&:focus': {
            borderColor: '#8E0038',
          },
          background: 'white',
        },
        underline: {
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
        },
      },
      MuiButton: {
        root: {
          textTransform: 'none',
          borderRadius: 0,
        },
      },
      MuiFormHelperText: {
        root: {
          '&$error': {
            color: ERROR_COLOR,
            fontSize: '12px',
            lineHeight: '24px',
          },
        },
      },
      MuiExpansionPanel: {
        root: {
          width: '100%',
        },
      },
      MuiExpansionPanelSummary: {
        root: {
          '&$focused': { // focus property does not work in current version
            backgroundColor: 'white',
          },
        },
      },
      MuiSelect: {
        select: {
          '&:focus': {
            // the only way to override default hideous color is by using !important
            background: 'white !important',
          },
        },
        root: {
          background: 'white !important',
        },
      },
      MuiAppBar: {
        root: {
          background: '#1B000B!important',
          alignItems: 'center',
          display: 'block',
        },
      },
      MuiDialogContentText: {
        root: {
          color: 'black',
          textAlign: 'center',
        },
      },
      MuiDialogActions: {
        root: {
          justifyContent: 'center',
          paddingBottom: '20px',
        },
      },
      MuiContainer: {
        root: {
          paddingBottom: '30px',
        },
      },
    },
  },
));
