import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

interface LinkButtonProps {
  to: string;
  exact?: boolean;
}

export function ButtonLink(props: ButtonProps & LinkButtonProps) {

  const { to, exact, children, ...remainingProps } = props;

  const history = useHistory();
  const goTo = (url: string) => history.push(url);

  // How to check if route is active:
  // const isRouteActive = useRouteMatch({
  //   path: to,
  //   exact: exact,
  // });

  return (
    <Button
      {...remainingProps}
      onClick={() => goTo(to)}>{children}
    </Button>
  );
}
