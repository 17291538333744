import { apiClient } from '../../clients/api-client/api.client';
import { terminPopupStore } from './TerminPopupStore';
import { logger } from '../logger/Logger';
import { notificationStore } from '../notification/NotificationStore';
import moment from 'moment';

export class TerminService {
  public async updateTermin() {
    const lead = terminPopupStore.lead;
    const terminDate = terminPopupStore.dueDate;
    const terminTime = terminPopupStore.dueDateTime;
    if (!lead) {
      terminPopupStore.closeModal();
      return;
    }

    if (!terminDate || !terminTime) {
      notificationStore.showError('Beim Speichern des Termins ist ein Fehler aufgetreten');
      return;
    }

    const dueDateTime = moment(terminTime);
    const dueDate = moment(terminDate)
      .hour(dueDateTime.hour())
      .minute(dueDateTime.minute())
      .toDate();

    try {
      terminPopupStore.isSaving = true;
      await apiClient.updateTermin(lead.id, dueDate);
    } catch (err) {
      logger.error(err);
      notificationStore.showError('Beim Speichern des Termins ist ein Fehler aufgetreten');
    } finally {
      terminPopupStore.isSaving = false;
      terminPopupStore.closeModal();
    }
  }

  public discardTermin() {
    terminPopupStore.closeModal();
  }
}

export const terminService = new TerminService();
