import { observable, action } from 'mobx';

class ResetPasswordStore {
  @observable public error: boolean = false;
  @observable public loading: boolean = false;

  @action
  public clear() {
    this.error = false;
    this.loading = false;
  }
}

export const resetPasswordStore = new ResetPasswordStore();
