import { action, observable } from 'mobx';
import { LeadModel } from '@ems/ems-lwl-models';
import { popupStore } from '../popup/PopupStore';

const popupName = 'AssureLeadLock';

class AssureLockLeadPopupStore {
  @observable public areYouSurePopupLead: LeadModel | null = null;

  public isAreYouSurePopupVisible() {
    return popupStore.popupVisible === popupName;
  }

  @action
  public showAreYouSurePopup(lead: LeadModel) {
    popupStore.requestOpen(popupName);
    this.areYouSurePopupLead = lead;
  }

  @action
  public closeAreYouSurePopup() {
    popupStore.requestClose(popupName);
    this.areYouSurePopupLead = null;
  }
}

export const assureLockLeadPopupStore = new AssureLockLeadPopupStore();
