import React, { Component } from 'react';
import {
  Box,
  Popper,
  Paper,
  ClickAwayListener,
  Button,
  Link,
  Divider,
} from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import { observer } from 'mobx-react';
import { appStore } from '../app-store/store/AppStore';
import { loginUserManager } from '../login/LoginUserManager';
import { StyledBackdrop } from '../live-update/SocketConnectionModal';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { liveUpdateStore } from '../live-update/LiveUpdateStore';
import { userMenuStore } from './UserMenuStore';

@observer
export class UserMenu extends Component {

  public render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const currentUser = appStore.currentUser; // MobX

    const isOtherBackdropVisible =
        liveUpdateStore.isDisconnected  ||
        liveUpdateStore.isReconnecting;

    const shouldShowMenu = userMenuStore.isMenuOpened && !isOtherBackdropVisible;

    return (
        <StyledBackdrop open={shouldShowMenu}>
          <Popper open={shouldShowMenu}
                  placement="bottom-end"
                  anchorEl={userMenuStore.menuAnchorElement}
                  style={{ zIndex: 99999999 }}>
              <Paper>
                {this.renderPopoverArrow()}
                <ClickAwayListener onClickAway={() => this.closeMenu()}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                  <Grid item>
                    <Box p={2} bgcolor="grey.100" style={{ borderRadius: '4px' }}>
                      {appStore.currentUser?.email}
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box p={2}>
                      <Box color="primary.main" fontWeight="bolder">
                        <Link href="/account-details">
                          Meine Daten
                        </Link></Box>
                    </Box>
                  </Grid>
                  <Grid item style={{ width: '100%' }}>
                    <Divider />
                  </Grid>
                  <Grid item>
                    <Box p={2}>
                      <Button
                          variant="contained"
                          color="primary"
                          onClick={() => this.logout()}
                      >
                        Abmelden
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                </ClickAwayListener>
              </Paper>
          </Popper>
        </StyledBackdrop>);
  }
  private renderPopoverArrow() {
    return(
      <ArrowDropUpIcon style={{
        color:'rgb(245, 245, 245)',
        position:'absolute',
        fontSize:'50px',
        top:'-29px',
        right:'6px' }}/>);
  }

  private logout() {
    loginUserManager.logout().then(() => window.location.assign('/'));
  }

  private closeMenu() {
    userMenuStore.close();
  }
}

export default UserMenu;
