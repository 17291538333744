import { observable, action } from 'mobx';

class LoginStore {
  @observable public error: boolean = false;
  @observable public loading: boolean = false;
  @observable public nonLwlUserError: boolean = false;
  @observable public passwordResetMail: string | null = null;

  @action
  public clear() {
    this.error = false;
    this.loading = false;
    this.nonLwlUserError = false;
    this.passwordResetMail = null;
  }
  @action
  public setPasswordResetMail(mail: string | null) {
    this.passwordResetMail = mail;
  }
}

export const loginStore = new LoginStore();
