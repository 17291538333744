import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import {
  Box,
  Toolbar,
  Chip,
  withStyles,
} from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import { observer } from 'mobx-react';
import { appStore } from '../app-store/store/AppStore';
import UserMenu from '../user-menu/UserMenu';
import { userMenuStore } from '../user-menu/UserMenuStore';
import { NewOfferPopup } from '../new-offer-popup/NewOfferPopup';

import NavigationButtons from './NavigationButtons';

const StyledToolbar = withStyles(theme => {
  return {
    root: {
      maxWidth: '1440px',
      margin: 'auto',
      height: '80px',
      minHeight: '80px',
      display: 'flex',
      background: 'linear-gradient(270deg, #1B000B 0%, #8E0038 48.53%, #1B000B 100%)',
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
      },
    },
  };
})(Toolbar);

const LwlChip = withStyles({
  root: {
    backgroundColor: 'white',
    borderRadius: '5px',
    height: 'auto',
    marginLeft: '20px',
  },
  label: {
    fontSize: '30px',
    padding: '0p',
    fontWeight: 'bold',
    lineHeight: '42px',
    paddingLeft: '6px',
    paddingRight: '6px',
    color: '#1D030D',
  },
})(Chip);

@observer
export class TopBar extends Component {

  public render() {
    const isUserLoggedIn = appStore.isUserAuthorised;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const currentUser = appStore.currentUser; // MobX
    return (
        <>
          <AppBar position="static">
          <StyledToolbar>
            <Grid container
                  direction="row"
                  justify="space-between"
                  alignItems="center">
              <Grid>
                <Grid container direction="row" alignItems="center">
                  <Grid>
                    <LwlChip label="LWL"/>
                  </Grid>
                  <Grid>
                    <Box ml={2}>
                      <Box fontWeight="fontWeightMedium" fontSize={28}>
                        Leadmanagement
                      </Box>
                      <Box fontWeight="fontWeightBold" fontSize={12}>
                        per Lead-Workload
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {isUserLoggedIn ? <NavigationButtons openMenu={this.openMenu}/> : null}
            </Grid>
          </StyledToolbar>
        </AppBar>
        <UserMenu/>
        <NewOfferPopup/>
      </>);
  }

  private openMenu(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    userMenuStore.openForElement(event.currentTarget);
  }
}

export default TopBar;
