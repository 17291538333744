import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Box,
  Typography,
  withStyles,
  DialogTitle,
  Divider,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import CloseIcon from '@material-ui/icons/Close';
import { leadSuccessModalStore } from './LeadSuccessModalStore';
import { LeadModel } from '@ems/ems-lwl-models';

const BoldText = withStyles({
  root: {
    fontWeight: 'bold',
    fontSize: 14,
  },
})(Typography);

@observer
export class LeadSuccessModal extends React.Component<{}> {

  public render() {
    const lead = leadSuccessModalStore.lead;
    if (!lead) {
      return <></>;
    }
    return (
      <Dialog open={leadSuccessModalStore.isDialogOpened()} onClose={this.close} scroll="paper">
        <DialogTitle>
          <IconButton aria-label="close" onClick={this.close}
            style={{ position: 'absolute', top: '10px', right: '10px' }}>
            <CloseIcon />
          </IconButton>
          <DialogContentText>
            <Box m={2} component="span" display="block" minWidth="500px"
              fontSize={24} fontWeight="fontWeightLight">
              Gratuliere,<br />der Lead wurde erfolgreich bearbeitet.
            </Box>
          </DialogContentText>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {this.renderLeadInfo(lead)}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.close}
            style={{ minWidth: 160 }}
            variant="outlined" color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  private renderLeadInfo = (lead: LeadModel) => {
    return (
      <table style={{ margin: 'auto' }}>
        <tbody>
          <tr>
            <td style={{ minWidth: 160 }}>
              <BoldText>Verkäufer ID</BoldText>
            </td>
            <td><Typography>{this.getDealerInfo(lead)}</Typography></td>
          </tr>
          <tr>
            <td><BoldText>Kunde</BoldText></td>
            <td><Typography>{this.getCustomerInfo(lead)}</Typography></td>
          </tr>
          <tr>
            <td><BoldText>Ang-Nr.</BoldText></td>
            <td><Typography>{lead?.offer.code}</Typography></td>
          </tr>
        </tbody>
      </table>
    );
  }

  private getDealerInfo = (lead: LeadModel) => {
    const firstName = this.noNullShow(lead.dealer.firstName);
    const lastName = this.noNullShow(lead.dealer.lastName);
    const organization = this.noNullShow(lead.dealer.organization);

    if (firstName === '' && lastName === '' && organization === '') {
      return '';
    }
    return `${firstName} ${lastName}, ${organization}`;
  }

  private getCustomerInfo = (lead: LeadModel) => {
    const firstName = this.noNullShow(lead.customer.firstName);
    const lastName = this.noNullShow(lead.customer.lastName);
    const phone = this.noNullShow(lead.customer.phone);
    if (firstName === '' && lastName === '' && phone === '') {
      return '';
    }
    return `${firstName} ${lastName}, ${phone}`;
  }

  private close = () => {
    leadSuccessModalStore.closeModal();
  }

  private noNullShow = (value: string | null | undefined): string => {
    if (value) {
      return value.toString();
    }
    return '';
  }
}
