import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { myLeadsStore } from './store/MyLeadsStore';
import { myLeadsManager } from './MyLeadsManager';
import { LeadModel } from '@ems/ems-lwl-models';
import {
  Box,
  Card,
  CircularProgress,
  Container,
  createMuiTheme,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  ThemeProvider,
  withStyles,
} from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import SubLabel from '../../components/SubLabel';
import { leadsTableThemeOptions } from '../theme/leadsTableTheme';
import CreateIcon from '@material-ui/icons/Create';
import VerticallyCenteredTableCell from '../../components/VerticallyCenteredTableCell';
import { LeadLockComponent } from '../lead-locks/LeadLockComponent';
import { LeadTableCommons } from './LeadTableCommons';
import { terminPopupStore } from '../termin-popup/TerminPopupStore';
import { leadUpdateModalStore } from '../lead-update/LeadUpdateModalStore';

const SmallButton = withStyles(theme => {
  return {
    root: {
      borderRadius: 0,
      textTransform: 'none',
      fontSize: 14,
      paddingLeft: 8,
      paddingRight: 8,
      paddingTop: 2,
      paddingBottom: 2,
    },
  };
})(Button);

@observer
export class MyLeadsTable extends Component {

  public componentDidMount() {
    this.fetchData();
  }

  public render() {

    const leads = this.getMyLeads();

    return (
      <Box bgcolor="grey.100" py={3}>
        <ThemeProvider theme={(theme: Theme) => {
          return createMuiTheme({ ...theme, ...leadsTableThemeOptions });
        }
        }>
          <Container>
            <Grid container justify="space-between">
              <Box m={1} mb={1.5} fontWeight="fontWeightBold" lineHeight={1.7}>
                {leads ? leads.length : 0} Leads In Bearbeitung
            </Box>
            </Grid>
            {this.renderTableSection(leads)}
          </Container>
        </ThemeProvider>
      </Box>);
  }

  private renderTableSection(leads: LeadModel[]) {
    if (this.isNoLeadsInProgress(leads)) {
      return this.renderNoLeadsInProgress();
    }
    return this.renderFullTable(myLeadsStore.isLoadingData, leads);
  }

  private isNoLeadsInProgress(leads: LeadModel[]): boolean {
    const isLoading = myLeadsStore.isLoadingData;
    return !isLoading && leads.length === 0;
  }

  private renderIsLoading() {
    return <TableRow>
      <TableCell colSpan={10} align="center">
        <CircularProgress />
      </TableCell>
    </TableRow>;
  }

  private renderFullTable(isLoading: boolean, leads: LeadModel[]) {
    return (<Card>
      <TableContainer key="table-container">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">*</TableCell>
              <TableCell>Eingang</TableCell>
              <TableCell>Verkäufer ID</TableCell>
              <TableCell>AG-Doc</TableCell>
              <TableCell>Kunde</TableCell>
              <TableCell>Fälligkeit</TableCell>
              <TableCell>Termin</TableCell>
              <TableCell>AnV</TableCell>
              <TableCell>Letztes Ergebnis</TableCell>
              <TableCell></TableCell>
              <TableCell align="right">Anrufversuch</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? this.renderIsLoading() : this.renderTableRows(leads)}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>);
  }

  private renderNoLeadsInProgress() {
    return <Box m={1} mb={1.5} fontSize="caption.fontSize">
      Derzeit arbeiten Sie nicht an einem Lead.
    </Box>;
  }

  private renderTableRows(leads: LeadModel[]) {
    return leads.map(lead => {
      return (
        <TableRow
          hover
          tabIndex={-1}
          key={lead.id}
          style={{ background: '#FFF9E3' }}
        >
          <TableCell>
            <LeadLockComponent lead={lead} />
          </TableCell>
          <TableCell>{LeadTableCommons.createdDateRenderer(lead)}</TableCell>
          <TableCell>{LeadTableCommons.dealerRenderer(lead)}</TableCell>
          <TableCell>{lead.offer.code}</TableCell>
          <TableCell>{LeadTableCommons.customerRenderer(lead)}</TableCell>
          <TableCell>{LeadTableCommons.expiredRenderer(lead)}</TableCell>
          <TableCell>{this.dueDateRenderer(lead)}</TableCell>
          <TableCell>{lead.status.contactAttempts}</TableCell>
          <TableCell>{LeadTableCommons.lastStatusRenderer(lead)}</TableCell>
          <VerticallyCenteredTableCell align="right">
            {LeadTableCommons.renderNoteButton(lead)}
          </VerticallyCenteredTableCell>
          <VerticallyCenteredTableCell align="right">
            <SmallButton variant="contained" color="primary"
              onClick={() => leadUpdateModalStore.showModal(lead)}>
              Erfassen
            </SmallButton>
          </VerticallyCenteredTableCell>
        </TableRow>
      );
    },
    );
  }

  private dueDateRenderer(row: LeadModel) {
    return (
      <div>
        <div>{LeadTableCommons.renderDate(row.status.dueDate)}</div>
        <SubLabel>
          {LeadTableCommons.renderTime(row.status.dueDate)}
          <Box ml="5px" component="span">
            <CreateIcon style={{ color: 'black', fontSize: '12px', cursor: 'pointer' }}
              onClick={() => terminPopupStore.openModal(row)}
            />
          </Box>
        </SubLabel>
      </div>
    );
  }

  private getMyLeads(): LeadModel[] {
    return myLeadsStore.leads;
  }

  private fetchData = () => {
    myLeadsManager.fetchMyLeads();
  }
}
