import { observer } from 'mobx-react';
import React, { ChangeEvent, Component } from 'react';
import { TextField, Box, Button, CircularProgress, Link } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Redirect } from 'react-router-dom';
import { loginUserManager } from './LoginUserManager';
import { appStore } from '../app-store/store/AppStore';
import { loginStore } from './LoginStore';

const TransparentButton = withStyles({
  root: {
    borderStyle: 'solid',
    border: '2px solid white',
    background: 'transparent',
    '&:hover': {
      background: 'rgba(99, 0, 39, 0.5)',
    },
  },
})(Button);

const LoginFormControlsBox = withStyles(theme => {
  return {
    root: {
      width: '500px',
      marginLeft: '200px',
      marginTop: '355px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        margin: '45% auto',
      },
    },
  };
},
)(Box);

const LogoinFieldFormControlsBox = withStyles(theme => {
  return {
    root: {
      width: '133%', // workaround to fool MuiInputLabel-shrink class
      display: 'flex',
      justifyContent: 'space-between',
    },
  };
})(Box);

interface LoginFormState {
  login: string;
  password: string;
}

const ENTER_KEY_CODE = 13;

@observer
export class LoginForm extends Component<{}, LoginFormState> {

  constructor(props: LoginFormState) {
    super(props);

    this.state = {
      login: '',
      password: '',
    };
  }

  public render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isLoginError = loginStore.loading; //  DO NOT REMOVE - needed by mobx
    const isAuthorised = appStore.isUserAuthorised;
    return (
        isAuthorised ?
            this.renderRedirection()
            :
            this.renderLoginForm()
    );
  }

  private renderRedirection() {
    return <Redirect to="/"/>;
  }

  private renderLabelPassword() {
    return (<LogoinFieldFormControlsBox component="div">
      <Box component="span" display="inline">Passwort</Box>
      <Link href="/reset-password" color="inherit">Passwort vergessen</Link>
    </LogoinFieldFormControlsBox>);
  }

  private renderLoginForm() {
    return (<Box height="100%"
                 alignItems="flex-start"
                 display="flex"
                 top="0"
                 marginTop="-80px"
                 minHeight="100vh">
      <Box width="100%">
        <LoginFormControlsBox>
          <form noValidate autoComplete="off">
            <Box fontSize={28} color="white" mb={4}>
              BITTE MELDEN SIE SICH AN
            </Box>
            <Box mt={2}>
              <TextField
                  id="email"
                  name="email"
                  value={this.state.login}
                  label="E-Mail Adresse"
                  onChange={this.handleLoginChange}
                  type="text"
                  onKeyUp={this.handleLoginOnEnter}
                  fullWidth={true}
                  InputLabelProps={{
                    style: { color: 'white' },
                  }}
              />
            </Box>
            <Box mt={2}>
              <TextField
                  id="password"
                  name="password"
                  value={this.state.password}
                  label={this.renderLabelPassword()}
                  onChange={this.handlePasswordChange}
                  fullWidth={true}
                  type="password"
                  onKeyUp={this.handleLoginOnEnter}
                  InputLabelProps={{
                    style: { color: 'white', width: '100%' },
                  }}
              />
            </Box>
            {loginStore.error && this.renderLoginError()}
            {loginStore.nonLwlUserError && this.renderNonLwlUserError()}
            <Box mt={2}>
              <TransparentButton
                  variant="contained"
                  color="primary"
                  onClick={this.handleLogin}
                  fullWidth={true}
                  disabled={loginStore.loading}
                  type="button"
              >
                {
                  loginStore.loading ?
                    <CircularProgress style={{ color: 'white', width:'24px', height:'24px' }}/> :
                    'Anmelden'
                }
              </TransparentButton>
            </Box>
          </form>
        </LoginFormControlsBox>
      </Box>
    </Box>);
  }

  private renderLoginError() {
    return <Box mt={2} color="#F44335" fontWeight="bold">E-Mail oder Passwort falsch</Box>;
  }

  private renderNonLwlUserError() {
    return <Box mt={2} color="#F44335" fontWeight="bold">
      Kein Zugriff, Sie sind nicht berechtigt.</Box>;
  }

  private handleLoginOnEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === ENTER_KEY_CODE) {
      this.handleLogin();
      e.preventDefault();
    }
  }

  private handleLoginChange =
      (e: ChangeEvent<HTMLInputElement>) => this.setState({ login: e.target.value })
  private handlePasswordChange =
      (e: ChangeEvent<HTMLInputElement>) => this.setState({ password: e.target.value })

  private handleLogin = async () => {
    try {
      await loginUserManager.loginUser(this.state.login, this.state.password);
    } catch (err) {
      this.setState({
        password: '',
      });
    }
  }
}
