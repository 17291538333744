import { observable } from 'mobx';
import { LeadModel } from '@ems/ems-lwl-models';
import { apiClient } from '../../../clients/api-client/api.client';
import { logger } from '../../logger/Logger';
import { popupStore } from '../../popup/PopupStore';
import { notificationStore } from '../../notification/NotificationStore';

const popupName = 'noteModal';

class NotePopupStore {

  @observable public notePopupLead: LeadModel | null = null;
  @observable public isNoteLoading: boolean = false;
  @observable public isSaving: boolean = false;
  @observable public note: string = '';

  public isDialogOpened() {
    return popupStore.popupVisible === popupName;
  }

  public showNotePopup(lead: LeadModel) {
    this.isNoteLoading = true;
    this.notePopupLead = lead;
    popupStore.requestOpen(popupName);
    this.loadNote(lead.id);
  }

  public closeNotePopup() {
    popupStore.requestClose(popupName);
  }

  private async loadNote(leadId: number): Promise<void> {
    try {
      const note = await apiClient.getNoteTextForLead(leadId);
      this.note = note;
    } catch (err) {
      logger.error('Fehler beim Laden der Bemerkung', err, leadId);
      this.closeNotePopup();
      notificationStore.showError('Fehler beim Laden der Bemerkung');
    } finally {
      this.isNoteLoading = false;
    }
  }

  public async saveNote() {
    const id = this.notePopupLead?.id;
    if (!id) {
      return;
    }
    try {
      this.isSaving = true;
      await apiClient.addOrUpdateNoteTextForLead(id, this.note);
    } catch (err) {
      logger.error('Fehler beim Speichern der Bemerkung', id, err);
      notificationStore.showError('Fehler beim Speichern der Bemerkung');
      return;
    } finally {
      this.isSaving = false;
    }
    notePopupStore.closeNotePopup();
  }
}

export const notePopupStore = new NotePopupStore();
