import { apiClient } from '../../clients/api-client/api.client';
import { leadStore } from './store/LeadStore';
import { tableStore } from './store/TableStore';
import { LeadFilterPanelModel } from '../../clients/api-client/models/lead-filter-panel.model';
import { StatusValue, ExpiredStatus } from '@ems/ems-lwl-models';
import { isEnum } from '@nova/typings';
import { Moment } from 'moment';
import { notificationStore } from '../notification/NotificationStore';

class LeadManager {

  public async fetchLeads(showSpinner: boolean = true): Promise<void> {
    try {
      if (showSpinner) {
        leadStore.isLoadingData = true;
      }

      const query = tableStore.leadQuery;
      const paginatedResponse = await apiClient.getLeads(query);

      leadStore.totalElements = paginatedResponse.count;
      leadStore.leads = paginatedResponse.leads;

    } catch (err) {
      notificationStore.showError('Ein Fehler ist aufgetreten');
    } finally {
      if (showSpinner) {
        leadStore.isLoadingData = false;
      }
    }
  }

  public setPage(page: number): void {
    tableStore.currentPage = page;
  }

  public setFilters(filter: LeadFilterPanelModel): void {
    tableStore.customerName = filter.customerName.trim();
    tableStore.dealer = filter.dealer.trim();
    tableStore.agDoc = filter.agDoc.trim();
    tableStore.expired = this.getExpiredFilter(filter.expired);
    tableStore.lastResult = this.getStatusFilter(filter.lastResult);
    tableStore.cooperationPartner = filter.cooperationPartner;

    tableStore.createdFrom = this.getDateWithoutTime(filter.createdFrom);
    tableStore.createdTo = this.getDateWithoutTime(filter.createdTo, true);
    tableStore.desiredContactDateFrom = this.getDateWithoutTime(filter.desiredContactDateFrom);
    tableStore.desiredContactDateTo = this.getDateWithoutTime(filter.desiredContactDateTo, true);

    tableStore.currentPage = 1;
  }

  private getStatusFilter(value: string): StatusValue | null {
    if (isEnum(StatusValue, value)) {
      return value;
    }
    return null;
  }

  private getExpiredFilter(value: string): ExpiredStatus | null {
    if (isEnum(ExpiredStatus, value)) {
      return value;
    }
    return null;
  }

  private getDateWithoutTime(date: Moment | null, inclusive: boolean = false): Moment | null {
    if (!date) {
      return null;
    }
    return inclusive
      ? date.endOf('day')
      : date.startOf('day');
  }
}

export const leadManager = new LeadManager();
