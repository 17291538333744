import { apiClient } from '../../clients/api-client/api.client';
import { notificationStore } from '../notification/NotificationStore';
import { salesChannelStore } from './store/SalesChannelStore';
import { logger } from '../logger/Logger';

class SalesChannelManager {

  public async fetchSalesChannels(): Promise<void> {
    try {
      salesChannelStore.areSalesChannelsLoading = true;
      const salesChannels = await apiClient.getAllSalesChannels();
      salesChannelStore.salesChannels = salesChannels;
      if (!salesChannels.length) {
        // tslint:disable-next-line: max-line-length
        logger.error('There are no Sales Channels! Add Sales Channels to sales_channels table in DB! Without any partners lwl-web won\'t launch!');
      }
    } catch (err) {
      notificationStore.showError('Ein Fehler ist aufgetreten');
    } finally {
      salesChannelStore.areSalesChannelsLoading = false;
    }
  }

}

export const salesChannelManager = new SalesChannelManager();
