import { ExpiredStatus } from '@ems/ems-lwl-models';

export class ExpiredStatusService {
  public static getLabel(expiredStatus: ExpiredStatus): string {
    switch (expiredStatus) {
      case ExpiredStatus.YES:
        return 'Ja';
      case ExpiredStatus.YES_WITH_TERMIN:
        return 'Ja Termin';
      case ExpiredStatus.NO:
        return 'Nein';
      case ExpiredStatus.NO_WITH_TERMIN:
        return 'Nein Termin';
      case ExpiredStatus.FINISHED:
        return 'Erledigt';
      case ExpiredStatus.UNKNOWN:
        return '';
    }
  }
}
