import React, { Component } from 'react';
import {
  Box,
  withStyles,
} from '@material-ui/core';
import { ButtonLink } from '../../components/ButtonLink';

import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DescriptionIcon from '@material-ui/icons/Description';

import Grid from '@material-ui/core/Grid';
import VerticalDivider from '../../components/VerticalDivider';
import Button from '@material-ui/core/Button';
import { newOfferPopupStore } from '../new-offer-popup/NewOfferPopupStore';
import FileWithPlusIcon from './FileWithPlusIcon';
import { coopPartnerManager } from '../coop-partners/CoopPartnerManager';
import { salesChannelManager } from '../sales-channels/SalesChannelManager';
import { coopPartnerStore } from '../coop-partners/store/CoopPartnerStore';
import { salesChannelStore } from '../sales-channels/store/SalesChannelStore';

const StyledButtonLink = withStyles({
  root: {
    fontSize: '16px',
    margin: 'auto 25px',
  },
})(ButtonLink);

const StyledButton = withStyles({
  root: {
    fontSize: '16px',
    margin: 'auto 25px',
  },
})(Button);

interface NavigationButtonsProps {
  openMenu(e: React.MouseEvent<HTMLButtonElement>): void;
}

class NavigationButtons extends Component<NavigationButtonsProps> {

  private handleOpenNewOfferPopup = async () => {

    if (!coopPartnerStore.arePartnersReady) {
      await coopPartnerManager.fetchPartners();
    }
    if (!salesChannelStore.areSalesChannelsReady) {
      await salesChannelManager.fetchSalesChannels();
    }

    newOfferPopupStore.showModal();
  }

  public render() {
    return (<Grid>
            <Grid container alignItems="center">
              <StyledButtonLink color="inherit" to="/" exact>
                <DescriptionIcon style={{ fontSize: 24, marginRight: '10px' }}/> Lead
                Management
              </StyledButtonLink>
              <VerticalDivider/>
              <StyledButton color="inherit" onClick={() => this.handleOpenNewOfferPopup()}>
              <FileWithPlusIcon/>
                Neue Fahrzeugsuche
              </StyledButton>
              <VerticalDivider/>
              <Box ml={1.5}>
                <IconButton color="inherit" onClick={
                  (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.props.openMenu(e)}>
                  <AccountCircleIcon style={{ fontSize: 36 }}/>
                </IconButton>
              </Box>
            </Grid>
          </Grid>);
  }
}

export default NavigationButtons;
