import { apiClient } from '../../clients/api-client/api.client';
import { myLeadsStore } from './store/MyLeadsStore';
import { notificationStore } from '../notification/NotificationStore';

class MyLeadsManager {

  public async fetchMyLeads(showSpinner: boolean = true): Promise<void> {
    try {
      if (showSpinner) {
        myLeadsStore.isLoadingData = true;
      }
      const myLeadsResponse = await apiClient.getMyLeads();
      myLeadsStore.leads = myLeadsResponse;
    } catch (err) {
      notificationStore.showError('Ein Fehler ist aufgetreten');
    } finally {
      myLeadsStore.isLoadingData = false;
    }
  }
}

export const myLeadsManager = new MyLeadsManager();
