import React, { Component } from 'react';
import { Container, Divider, CircularProgress, Box } from '@material-ui/core';
import { LeadsTable } from '../modules/leads/LeadsTable';
import { LeadsFilter } from '../modules/leads/LeadsFilter';
import { AssureLockLeadPopup } from '../modules/assure-lead-lock-popup/AssureLockLeadPopup';
import { MyLeadsTable } from '../modules/leads/MyLeadsTable';
import { observer } from 'mobx-react';
import { appStore } from '../modules/app-store/store/AppStore';
import { statusesManager } from '../modules/statuses/StatusesManager';
import { coopPartnerManager } from '../modules/coop-partners/CoopPartnerManager';
import { statusesStore } from '../modules/statuses/store/StatusesStore';
import { Notification } from '../modules/notification/Notification';
import { NotePopup } from '../modules/note/note-popup/NotePopup';
import { TerminPopup } from '../modules/termin-popup/TerminPopup';
import { LeadUpdateModal } from '../modules/lead-update/LeadUpdateModal';
import { liveUpdateService } from '../modules/live-update/LiveUpdate.service';
import { SocketConnectionModal } from '../modules/live-update/SocketConnectionModal';
import { LeadSuccessModal } from '../modules/lead-success/LeadSuccessModal';
import { coopPartnerStore } from '../modules/coop-partners/store/CoopPartnerStore';

@observer
export class Main extends Component {

  public componentDidMount() {
    if (!statusesStore.areStatusesReady && !statusesStore.areStatusesLoading) {
      statusesManager.fetchStatuses();
    }
    if (!coopPartnerStore.arePartnersReady && !coopPartnerStore.arePartnersLoading) {
      coopPartnerManager.fetchPartners();
    }
    liveUpdateService.start();
  }
  public componentWillUnmount() {
    liveUpdateService.stop();
  }

  public render() {
    const isAppReady = appStore.isAppReady;

    if (!isAppReady) {
      return this.renderSpinner();
    }

    return (
      <>
        <MyLeadsTable />
        <Container>
          <LeadsFilter />
          <Divider style={{ margin: '25px 0px' }} />
          <LeadsTable />
          <AssureLockLeadPopup />
          <NotePopup />
          <Notification />
          <TerminPopup />
          <LeadUpdateModal />
          <LeadSuccessModal />
          <SocketConnectionModal />
        </Container>
      </>
    );
  }

  private renderSpinner() {
    return (
      <Container>
        <Box display="flex" justifyContent="center" marginTop="55px">
          <CircularProgress />
        </Box>
      </Container>
    );
  }
}
