import { observable } from 'mobx';
import { LeadModel } from '@ems/ems-lwl-models';

class LeadStore {
  @observable public isLoadingData = false;
  @observable public leads: LeadModel[] = [];

  /**
   * Total count of elements available on server
   */
  @observable public totalElements: number = 0;

}

export const leadStore = new LeadStore();
