const labels = {
  SIND_SIE_SICHER: 'Sind sie sicher, dass Sie diesen Lead in Bearbeitung nehmen wollen?',
  NEIN: 'Nein, abbrechen',
  JA: 'Ja, bearbeiten',
  STATUS_ERLEDIGT: 'Dieser Lead ist markiert als "Erledigt".',
  STATUS_NEIN: 'Dieser Lead ist markiert als "Nein".',
  STATUS_NEIN_TERMIN: 'Dieser Lead ist markiert als "Nein Termin".',
};

export default labels;
