import React from 'react';
import { KeyboardTimePicker, KeyboardTimePickerProps } from '@material-ui/pickers';
import ScheduleIcon from '@material-ui/icons/Schedule';

export class TimePicker extends React.Component<KeyboardTimePickerProps> {
  public render() {
    const defaultProps: Partial<KeyboardTimePickerProps> = {
      inputVariant: 'standard',
      variant: 'inline',
      fullWidth: true,
      keyboardIcon: <ScheduleIcon />,
      format: 'HH:mm',
      placeholder: 'HH:mm',
      autoOk: true,
      ampm: false,
      invalidDateMessage: 'Falsches Zeitformat',
    };

    const calculatedProps = {
      ...defaultProps,
      ...this.props,
    };

    return <KeyboardTimePicker
      {...calculatedProps}
    />;
  }
}
