import React from 'react';
import { LeadModel } from '@ems/ems-lwl-models';
import { leadLockManager } from './LeadLockManager';
import { Box, CircularProgress, Checkbox } from '@material-ui/core';
import { observer } from 'mobx-react';
import { assureLockLeadPopupStore } from '../assure-lead-lock-popup/AssureLockLeadPopupStore';

interface Props {
  lead: LeadModel;
  disabled?: boolean | undefined;
}

@observer
export class LeadLockComponent extends React.Component<Props> {
  public render() {
    const lead = this.props.lead;
    const isDisabled = this.props.disabled ? this.props.disabled : false;
    const isLockInProgress = leadLockManager.isLockInProgress(lead);
    if (isLockInProgress && !isDisabled) {
      return <Box width={24} height={24}><CircularProgress /></Box>;
    }
    const isLocked = leadLockManager.isLocked(lead);

    return <Checkbox color="primary"
      checked={isLocked}
      disabled={isDisabled}
      onClick={() => this.toggleLeadLock(lead)}
    />;
  }

  private toggleLeadLock(lead: LeadModel) {
    if (this.props.disabled) {
      return;
    }

    const isLocked = leadLockManager.isLocked(lead);
    if (isLocked) {
      return leadLockManager.unlockLead(lead);
    }

    const shouldShowConfirmationDialog = leadLockManager.shouldLockBeConfirmed(lead);
    if (shouldShowConfirmationDialog) {
      assureLockLeadPopupStore.showAreYouSurePopup(lead);
    } else {
      leadLockManager.lockLead(lead);
    }
  }
}
