import React, { Component } from 'react';
import { Container, Box, Paper, Typography, Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { appStore } from '../modules/app-store/store/AppStore';
import { UserModel } from '@ems/ems-lwl-models/dist';

@observer
export class AccountDetails extends Component {

  public render() {
    const currentUser = appStore.currentUser; // MobX
    return (
        <Container>
          <Box my={3}>
            <Typography variant="h4" align="center">
              Meine Daten
            </Typography>
          </Box>
          <Paper>
            <Box p={3}>
            <Grid container direction="column">
              {this.renderAccountData(currentUser)}
            </Grid>
            </Box>
          </Paper>
        </Container>
    );
  }

  private renderAccountData(currentUser: UserModel | null) {
    if (currentUser) {
      return [
        this.renderLabelValuePair('PERSONALNUMMER', currentUser.employeeNumber),
        this.renderLabelValuePair('VORNAME', currentUser.firstName),
        this.renderLabelValuePair('NACHNAME', currentUser.lastName),
        this.renderLabelValuePair('E-MAIL ADRESSE', currentUser.email),
        this.renderLabelValuePair('NIEDERLASSUNG / AUTOHAUS ID', currentUser.dealership),
        this.renderLabelValuePair('NIEDERLASSUNG / AUTOHAUS', '-'),
        this.renderLabelValuePair('ROLLE', currentUser.roleName),
        this.renderLabelValuePair('VORGESETZTER', '-'),
      ];
    }
  }

  private renderLabelValuePair(label: string, value: string | null) {
    return(
        <Box my={1.75} ml={4} key={label}>
          <Box color="grey.500">{label}</Box>
          <Box fontWeight="bold">{value}</Box>
        </Box>);
  }
}
