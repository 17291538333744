import React, { Component } from 'react';
import { Container, Typography } from '@material-ui/core';

export class NotFound extends Component {

  public render() {
    return (
      <Container maxWidth="sm" component="main">
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          Not Found!
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" component="p">
          These Are Not the Droids You Are Looking For
        </Typography>
      </Container>
    );
  }
}
