import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { SortableHeaderCell } from '../../components/SortableHeaderCell';
import { tableStore } from './store/TableStore';
import { LeadSortableColumn } from '@ems/ems-lwl-models';
import { leadManager } from './LeadManager';
import { TableCellProps } from '@material-ui/core';

interface SortableHeaderCellProps {
  id?: LeadSortableColumn;
  label?: string;
  sortable?: boolean;
  align?: TableCellProps['align'];
  children: React.ReactElement<any, any> | string;
}

@observer
export class HeaderCell extends Component<SortableHeaderCellProps, {}> {

  public render() {
    const { id, label, sortable, align, children } = this.props;
    const sortBy: string | null = tableStore.sortBy ? tableStore.sortBy : null;

    return <SortableHeaderCell
      id={id?.toString()}
      onClick={() => {
        tableStore.toggleSortBy(id);
        leadManager.fetchLeads();
      }}
      align={align}
      label={label}
      sortBy={sortBy}
      sortable={sortable}
      sortDirection={tableStore.sortDirection}>
      {children}
    </SortableHeaderCell>;
  }
}
