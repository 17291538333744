import * as React from 'react';
import { DialogTitle, IconButton, DialogContentText, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { newOfferPopupStore } from '../NewOfferPopupStore';

const NewOfferPopupHeader = () => (<DialogTitle>
    <IconButton
        aria-label="close"
        onClick={() => newOfferPopupStore.closeModal()}
        style={{ position: 'absolute', top: '10px', right: '10px' }}
    >
        <CloseIcon />
    </IconButton>
    <DialogContentText>
        <Box
            fontWeight="fontWeightBold"
            m={2}
            mr={5}
            ml={2}
            component="span"
            display="block"
        >
            Eine Neue Fahrzeugsuche erfolgt im <br /> Namen von:
          </Box>
    </DialogContentText>
</DialogTitle>);

export default NewOfferPopupHeader;
