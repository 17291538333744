import React, { Component, CSSProperties } from 'react';
import { observer } from 'mobx-react';
import { leadStore } from './store/LeadStore';
import { tableStore } from './store/TableStore';
import { leadManager } from './LeadManager';
import { LeadModel, ExpiredStatus, LeadSortableColumn } from '@ems/ems-lwl-models';
import {
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  CircularProgress,
  Card,
  ThemeProvider,
  Box,
} from '@material-ui/core';
import { leadsTableThemeOptions } from '../theme/leadsTableTheme';
import VerticallyCenteredTableCell from '../../components/VerticallyCenteredTableCell';
import SubLabel from '../../components/SubLabel';
import { LeadLockComponent } from '../lead-locks/LeadLockComponent';
import { LeadsTablePagination } from '../leads-table-pagination/LeadsTablePagination';
import { HeaderCell } from './HeaderCell';
import { LeadTableCommons } from './LeadTableCommons';

@observer
export class LeadsTable extends Component {

  public componentDidMount() {
    this.fetchData();
  }

  public render() {
    const isLoading = leadStore.isLoadingData;
    const leads = this.getLeads();
    const totalElements = leadStore.totalElements;
    const currentPage = tableStore.currentPage - 1;
    const rowsPerPage = tableStore.rowsPerPage;

    return (
      <ThemeProvider theme={leadsTableThemeOptions}>
        {this.renderSearchResultCountLabel()}
        <Card>
          <TableContainer
            key="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  <HeaderCell label="in Bearbeitung" align="center">*</HeaderCell>
                  <HeaderCell sortable id={LeadSortableColumn.created}>Eingang</HeaderCell>
                  <HeaderCell sortable id={LeadSortableColumn.dealer}>Verkäufer ID</HeaderCell>
                  <HeaderCell sortable id={LeadSortableColumn.agDoc}>AG-Doc</HeaderCell>
                  <HeaderCell sortable id={LeadSortableColumn.customerName}>Kunde</HeaderCell>
                  <HeaderCell sortable id={LeadSortableColumn.expired}>Fälligkeit</HeaderCell>
                  <HeaderCell sortable id={LeadSortableColumn.dueDate}>Termin</HeaderCell>
                  <HeaderCell sortable label="Anwahlversuche"
                    id={LeadSortableColumn.contactAttempts}>
                    AnV
                  </HeaderCell>
                  <HeaderCell sortable id={LeadSortableColumn.lastStatus}>
                    Letztes Ergebnis
                  </HeaderCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? this.renderIsLoading() : this.renderTable(leads)}
              </TableBody>
            </Table>
          </TableContainer>
          <LeadsTablePagination
            count={totalElements}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={this.onPageChange}
            onChangeRowsPerPage={this.onRowsPerPageChange}
          />
        </Card>
      </ThemeProvider>);
  }

  private renderSearchResultCountLabel() {
    if (tableStore.isFilterApplied && !leadStore.isLoadingData) {
      return <Box m={1} mt={-1.5} mb={2} fontWeight="fontWeightBold" lineHeight={1.7}>
        {leadStore.totalElements} Ergebnisse gefunden
      </Box>;
    }
  }

  private renderIsLoading() {
    return <TableRow>
      <TableCell colSpan={10} align="center">
        <CircularProgress />
      </TableCell>
    </TableRow>;
  }

  private renderTable(leads: LeadModel[]) {
    if (!leads.length) {
      return this.renderNoResultsTable();
    }

    return leads.map(lead => {

      const isRowDisabled = !!lead.lockedBy;

      return (
        <TableRow
          hover
          tabIndex={-1}
          key={lead.id}
          style={this.getRowStyle(lead)}
        >
          <TableCell>
            <LeadLockComponent lead={lead} disabled={isRowDisabled} />
          </TableCell>
          <TableCell>{LeadTableCommons.createdDateRenderer(lead)}</TableCell>
          <TableCell>{LeadTableCommons.dealerRenderer(lead)}</TableCell>
          <TableCell>{lead.offer.code}</TableCell>
          <TableCell>{LeadTableCommons.customerRenderer(lead)}</TableCell>
          <TableCell>{LeadTableCommons.expiredRenderer(lead, isRowDisabled)}</TableCell>
          <TableCell>{this.dueDateRenderer(lead)}</TableCell>
          <TableCell>{lead.status.contactAttempts}</TableCell>
          <TableCell>{LeadTableCommons.lastStatusRenderer(lead)}</TableCell>
          <VerticallyCenteredTableCell align="right">
            {lead.hasNote ? LeadTableCommons.renderNoteButton(lead) : <></>}
          </VerticallyCenteredTableCell>
        </TableRow>);
    },
    );
  }

  private getRowStyle(lead: LeadModel): CSSProperties {
    if (lead.lockedBy) {
      return { background: '#EFEFEF' };
    }
    const isLeadFinished: boolean = this.isLeadFinished(lead);
    if (isLeadFinished) {
      return { background: '#F8F8F8' };
    }
    return {};
  }

  private isLeadFinished(lead: LeadModel) {
    const expiredStatus: ExpiredStatus = lead.status.expiredStatus;
    return expiredStatus === ExpiredStatus.FINISHED;
  }

  private renderNoResultsTable() {
    return (
      <TableRow tabIndex={-1} key={0}>
        <TableCell colSpan={10} align={'center'}>Keine Ergebnisse</TableCell>
      </TableRow>
    );
  }

  private onPageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number,
  ) => {
    const pageInStore = page + 1;
    if (tableStore.currentPage === pageInStore) {
      return;
    }
    this.setPage(pageInStore);
    this.fetchData();
  }

  private onRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    tableStore.setRowsPerPage(rowsPerPage);
    this.fetchData();
  }

  private getLeads(): LeadModel[] {
    return leadStore.leads.slice(); // leads is not a real table
  }

  private setPage = (page: number) => {
    leadManager.setPage(page);
  }

  private fetchData = () => {
    leadManager.fetchLeads();
  }

  private dueDateRenderer = (row: LeadModel) => {
    return (
      <div>
        <div>{LeadTableCommons.renderDate(row.status.dueDate)}</div>
        <SubLabel>{LeadTableCommons.renderTime(row.status.dueDate)}</SubLabel>
      </div>
    );
  }
}
