class Logger {
  public warn(message?: any, ...optionalParams: any[]): void {
    // tslint:disable-next-line: no-console
    console.warn(message, optionalParams);
  }
  public error(message?: any, ...optionalParams: any[]): void {
    // tslint:disable-next-line: no-console
    console.error(message, optionalParams);
  }
  public log(message?: any, ...optionalParams: any[]): void {
    // tslint:disable-next-line: no-console
    console.log(message, optionalParams);
  }
}

export const logger = new Logger();
