import { DealershipQuery, UserQuery } from '@ems/ems-lwl-models';
import { apiClient } from '../../clients/api-client/api.client';
import { notificationStore } from '../notification/NotificationStore';
import { newOfferPopupStore } from './NewOfferPopupStore';

class NewOfferPopupManager {
  public async fetchDealerUsers(query: UserQuery): Promise<void> {
    try {
      const dealerUsers = await apiClient.getDealerUsers(query);
      newOfferPopupStore.dealerUsers = dealerUsers;
    } catch (err) {
      notificationStore.showError('Ein Fehler ist aufgetreten');
    }
  }

  public async fetchDealerships(query: DealershipQuery): Promise<void> {
    try {
      const dealerships = await apiClient.getDealerships(query);
      newOfferPopupStore.dealerships = dealerships;
    } catch (err) {
      notificationStore.showError('Ein Fehler ist aufgetreten');
    }
  }

  public async fetchAllBy(partner: string) {
    const params = { organization: partner };
    await this.fetchDealerUsers(params);
    await this.fetchDealerships(params);
  }

}

export const newOfferPopupManager = new NewOfferPopupManager();
