import withStyles from '@material-ui/core/styles/withStyles';
import { TableCell } from '@material-ui/core';

const VerticallyCenteredTableCell = withStyles({
  root: {
    verticalAlign: 'middle',
  },
})(TableCell);

export default VerticallyCenteredTableCell;
