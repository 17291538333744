import { action, observable } from 'mobx';

class UserMenuStore {
  @observable public menuAnchorElement: HTMLButtonElement | null = null;
  @observable public isMenuOpened: boolean = false;

  @action
  public openForElement(anchorElement: HTMLButtonElement | null) {
    this.menuAnchorElement = anchorElement;
    this.isMenuOpened = true;
  }

  @action
  public close() {
    this.isMenuOpened = false;
  }
}

export const userMenuStore = new UserMenuStore();
