import React, { ChangeEvent, Component } from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button/Button';
import Grid from '@material-ui/core/Grid/Grid';
import { tableStore } from './store/TableStore';
import { observer } from 'mobx-react';
import { Moment } from 'moment';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Box from '@material-ui/core/Box/Box';
import ClearIcon from '@material-ui/icons/Clear';
import { leadManager } from './LeadManager';
import { LeadFilterPanelModel } from '../../clients/api-client/models/lead-filter-panel.model';
import { ExpiredStatus, StatusValue } from '@ems/ems-lwl-models';
import { ObjectKeys } from '@nova/typings';
import { ExpiredStatusService } from './ExpiredStatusService';
import { Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { filterErrorStore } from './store/LeadsFilterErrorStore';
import { DatePicker } from '../../components/DatePicker';
import { statusesManager } from '../statuses/StatusesManager';
import { coopPartnerStore } from '../coop-partners/store/CoopPartnerStore';

// tslint:disable:-next-line no-empty-interface
interface LeadsFilterProps {
}

interface LeadsFilterState {
  customerName: string;
  dealer: string;
  agDoc: string;
  expired: string;
  createdFrom: Moment | null;
  createdTo: Moment | null;
  lastResult: string;
  cooperationPartner: string;
  desiredContactDateFrom: Moment | null;
  desiredContactDateTo: Moment | null;

  filtersExpanded: boolean;
}

@observer
export class LeadsFilter extends Component<LeadsFilterProps, LeadsFilterState> {

  constructor(props: LeadsFilterState) {
    super(props);

    this.state = {
      customerName: tableStore.customerName,
      dealer: tableStore.dealer,
      agDoc: tableStore.agDoc,
      expired: tableStore.expired || '',
      createdFrom: tableStore.createdFrom,
      createdTo: tableStore.createdTo,
      lastResult: tableStore.lastResult || '',
      cooperationPartner: tableStore.cooperationPartner || '',
      desiredContactDateFrom: tableStore.desiredContactDateFrom,
      desiredContactDateTo: tableStore.desiredContactDateTo,

      filtersExpanded: false,
    };
  }

  public render() {
    return (
      <form noValidate autoComplete="off">
        <Box my={3}>
          <Typography variant="h4" align="center">
            Wählen Sie einen Auftrag
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <ExpansionPanel elevation={0} expanded={this.state.filtersExpanded}>
            <ExpansionPanelSummary>
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={2}>
                  <TextField
                    id="customerName"
                    name="customerName"
                    value={this.state.customerName}
                    label="Kunde"
                    onChange={this.handleCustomerNameChange}
                    type="text"
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    id="dealer"
                    name="dealer"
                    value={this.state.dealer}
                    label="Verkäufer-ID"
                    onChange={this.handleDealerChange}
                    type="text"
                    fullWidth={true}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    id="agDoc"
                    name="agDoc"
                    value={this.state.agDoc}
                    label="AG-DOC"
                    onChange={this.handleAgDocChange}
                    type="text"
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Box>
                    <TextField
                      id="expired"
                      name="expired"
                      value={this.state.expired}
                      label="Fälligkeit"
                      onChange={this.handleExpiredChange}
                      select
                      fullWidth={true}
                    >
                      {this.renderExpiredFilterOptions()}
                    </TextField>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <DatePicker
                    id="createdFrom"
                    name="createdFrom"
                    value={this.state.createdFrom}
                    label="Eingang von"
                    onChange={this.handleCreatedFromChange}
                    onError={(_, value) => filterErrorStore.setCreatedFrom(value)}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <DatePicker
                    id="createdTo"
                    name="createdTo"
                    value={this.state.createdTo}
                    label="bis"
                    onChange={this.handleCreatedToChange}
                    onError={(_, value) => filterErrorStore.setCreatedTo(value)}
                  />
                </Grid>
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={2}>
                  <Box>
                    <TextField
                      id="lastResult"
                      name="lastResult"
                      value={this.state.lastResult}
                      label="Letztes Ergebnis"
                      onChange={this.handleLastResultChange}
                      select
                      fullWidth={true}
                    >
                      {this.renderStatusFilterOptions()}
                    </TextField>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Box>
                    <TextField
                      id="cooperationPartner"
                      name="cooperationPartner"
                      value={this.state.cooperationPartner}
                      label="Kooperationspartner"
                      onChange={this.handleCoopPartnerChange}
                      select
                      fullWidth={true}
                    >
                      {this.renderCoopPartnerFilterOptions()}
                    </TextField>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <DatePicker
                    id="desiredContactDateFrom"
                    name="desiredContactDateFrom"
                    value={this.state.desiredContactDateFrom}
                    label="Termin von"
                    onChange={this.handleDesiredContactDateFromChange}
                    onError={(_, value) => filterErrorStore.setTerminFrom(value)}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <DatePicker
                    id="desiredContactDateTo"
                    name="desiredContactDateTo"
                    value={this.state.desiredContactDateTo}
                    label="bis"
                    onChange={this.handleDesiredContactDateToChange}
                    onError={(_, value) => filterErrorStore.setTerminTo(value)}
                  />
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
        <Box my={2}>
          <Grid container item spacing={2} alignContent="center" justify="space-between">
            <Box m={1}>
              {this.renderExpansionToggleButton()}
            </Box>
            <Box>
              <Grid container justify="flex-end">
                <Box m={1}>
                  <Button endIcon={<ClearIcon />}
                    onClick={this.clearForm}
                    variant="text"
                    fullWidth={true}
                    color="primary"
                    disabled={!tableStore.isFilterApplied}
                  >
                    Suchergebnisse löschen
                  </Button>
                </Box>
                <Box m={1} >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.filterData}
                    fullWidth={true}
                    style={{
                      minWidth: '170px',
                    }}
                    disabled={!filterErrorStore.areFiltersCorrect}
                  >
                    Suchen
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </form>
    );
  }

  private handleCustomerNameChange =
    (e: ChangeEvent<HTMLInputElement>) => this.setState({ customerName: e.target.value })
  private handleDealerChange =
    (e: ChangeEvent<HTMLInputElement>) => this.setState({ dealer: e.target.value })
  private handleAgDocChange =
    (e: ChangeEvent<HTMLInputElement>) => this.setState({ agDoc: e.target.value })
  private handleExpiredChange =
    (e: ChangeEvent<HTMLInputElement>) => this.setState({ expired: e.target.value })
  private handleCreatedFromChange =
    (selectedDate: MaterialUiPickersDate) => this.setState({ createdFrom: selectedDate })
  private handleCreatedToChange =
    (selectedDate: MaterialUiPickersDate) => this.setState({ createdTo: selectedDate })
  private handleLastResultChange =
    (e: ChangeEvent<HTMLInputElement>) => this.setState({ lastResult: e.target.value })
  private handleCoopPartnerChange =
    (e: ChangeEvent<HTMLInputElement>) => this.setState({ cooperationPartner: e.target.value })
  private handleDesiredContactDateFromChange =
    (selectedDate: MaterialUiPickersDate) => this.setState({ desiredContactDateFrom: selectedDate })
  private handleDesiredContactDateToChange =
    (selectedDate: MaterialUiPickersDate) => this.setState({ desiredContactDateTo: selectedDate })

  private clearForm = () => {
    this.setState(
      {
        customerName: '',
        dealer: '',
        agDoc: '',
        expired: '',
        createdFrom: null,
        createdTo: null,
        lastResult: '',
        cooperationPartner: '',
        desiredContactDateFrom: null,
        desiredContactDateTo: null,
      },
      () => this.setCurrentFiltersAndFilterData(),
    );
  }

  private filterData = () => {
    this.setCurrentFiltersAndFilterData();
  }

  private setCurrentFiltersAndFilterData() {
    leadManager.setFilters(this.getFilterModelFromState());
    leadManager.fetchLeads();
  }

  private renderExpansionToggleButton() {
    const isExpanded = this.state.filtersExpanded;
    const buttonText = !isExpanded ? 'Erweiterte Suche' : 'Weniger anzeigen';
    const buttonIcon = !isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />;
    return <Button
      endIcon={buttonIcon}
      onClick={this.toggleFilterExpansion}
      variant="text"
      color="primary"
    >
      {buttonText}
    </Button>;
  }

  private toggleFilterExpansion = () => {
    const isExpanded = this.state.filtersExpanded;
    this.setState({
      filtersExpanded: !isExpanded,
    });
  }

  private getFilterModelFromState(): LeadFilterPanelModel {
    const currentFilterModel: LeadFilterPanelModel =
      (({
        customerName,
        dealer,
        agDoc,
        expired,
        createdFrom,
        createdTo,
        lastResult,
        cooperationPartner,
        desiredContactDateFrom,
        desiredContactDateTo,
      }) => ({
        customerName,
        dealer,
        agDoc,
        expired,
        createdFrom,
        createdTo,
        lastResult,
        cooperationPartner,
        desiredContactDateFrom,
        desiredContactDateTo,
      }))(this.state);
    return currentFilterModel;
  }

  private renderStatusFilterOptions() {
    const options = ObjectKeys(StatusValue).map(key => {
      const value = StatusValue[key];
      const label = statusesManager.translateStatus(value);
      return <MenuItem key={value} value={value}>{label}</MenuItem>;
    });

    return [
      <MenuItem key="" value="">
        <em>Alle </em>
      </MenuItem>,
      ...options,
    ];
  }

  private renderCoopPartnerFilterOptions() {
    const partners = coopPartnerStore.partners;
    const options = partners.map(partner => {
      return <MenuItem key={partner.value} value={partner.value}>{partner.label}</MenuItem>;
    });

    return [
      <MenuItem key="" value="">
        <em>Alle </em>
      </MenuItem>,
      ...options,
    ];
  }
  private renderExpiredFilterOptions() {
    const options = ObjectKeys(ExpiredStatus)
      .filter(key => key !== ExpiredStatus.UNKNOWN)
      .map(key => {
        const value = ExpiredStatus[key];
        const label = ExpiredStatusService.getLabel(value);
        return <MenuItem key={value} value={value}>{label}</MenuItem>;
      });

    return [
      <MenuItem key="" value="">
        <em>Alle </em>
      </MenuItem>,
      ...options,
    ];
  }

}
