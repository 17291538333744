import { ThemeOptions } from '@material-ui/core';

export const timepickerThemeOptions: ThemeOptions = {
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: 'white',
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: 'black',
      },
      toolbarBtnSelected: {
        color: 'black !important',
      },
    },
    MuiPickersTimePickerToolbar: {
      ampmLabel: {
        color: '#999999',
      },
    },
  },
};
