import { observable } from 'mobx';

class LiveUpdateStore {

  @observable
  public isReconnecting: boolean = false;

  @observable
  public isDisconnected: boolean = false;

}

export const liveUpdateStore = new LiveUpdateStore();
(window as any).liveUpdateStore = liveUpdateStore; // TODO - only for debugging
