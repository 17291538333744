import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

interface RuleCheckProps {
  text: string;
  helper: boolean;
  valid: boolean;
}

export function RuleCheck(props: RuleCheckProps) {
  const { text, helper, valid } = props;

  const renderIcon = () => {
    if (helper) {
      return <InfoOutlinedIcon style={{ marginRight: 10 }} />;
    }
    if (valid) {
      return <CheckCircleOutlineRoundedIcon style={{ marginRight: 10 }} />;
    }
    return <HighlightOffRoundedIcon style={{ marginRight: 10 }} />;
  };

  return (
    <Box style={{ color: 'white', paddingTop: 5 }}>
      <Typography style={{ display: 'flex' }}>
        {renderIcon()} {text}
      </Typography>
    </Box>
  );
}

RuleCheck.propTypes = {
  text: PropTypes.string,
  helper: PropTypes.bool,
  valid: PropTypes.bool,
};

RuleCheck.defaultProps = {
  text: 'Some text',
  helper: false,
  valid: false,
};
