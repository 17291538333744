import { apiClient } from '../../clients/api-client/api.client';
import { notificationStore } from '../notification/NotificationStore';
import { coopPartnerStore } from './store/CoopPartnerStore';
import { logger } from '../logger/Logger';

class CoopPartnerManager {

  public async fetchPartners(): Promise<void> {
    try {
      coopPartnerStore.arePartnersLoading = true;

      const partners = await apiClient.getAllCoopPartners();
      coopPartnerStore.partners = partners;
      if (!partners.length) {
        // tslint:disable-next-line: max-line-length
        logger.error('There are no Cooperation Partners! Add partners to partners table in DB! Without any partners lwl-web won\'t launch!');
      }
    } catch (err) {
      notificationStore.showError('Ein Fehler ist aufgetreten');
    } finally {
      coopPartnerStore.arePartnersLoading = false;
    }
  }

}

export const coopPartnerManager = new CoopPartnerManager();
