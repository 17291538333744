import { observer } from 'mobx-react';
import React, { ChangeEvent, Component } from 'react';
import { resetPasswordManager } from './ResetPasswordManager';
import { TextField, Box, Button, CircularProgress } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { resetPasswordStore } from './ResetPasswordStore';
import { logger } from '../logger/Logger';
import { loginStore } from '../login/LoginStore';
import { RESET_PASSWORD_FORCE_TITLE, RESET_PASSWORD_TITLE } from './Constants';

const ENTER_KEY_CODE = 13;

const TransparentButton = withStyles({
  root: {
    borderStyle: 'solid',
    border: '2px solid white',
    background: 'transparent',
    '&:hover': {
      background: 'rgba(99, 0, 39, 0.5)',
    },
  },
})(Button);

const ResetPasswordFormControlsBox = withStyles((theme) => {
  return {
    root: {
      width: '500px',
      marginLeft: '200px',
      marginTop: '355px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        margin: '45% auto',
      },
    },
  };
})(Box);
interface ResetPasswordFormState {
  email: string;
}

@observer
export class ResetPasswordForm extends Component<{}, ResetPasswordFormState> {
  constructor(props: ResetPasswordFormState) {
    super(props);
    this.state = {
      email: loginStore.passwordResetMail
        ? loginStore.passwordResetMail
        : '',
    };
  }

  public render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isError = resetPasswordStore.loading; //  DO NOT REMOVE - needed by mobx
    const isPasswordResetMail = !!loginStore.passwordResetMail;
    return (
      <Box
        height="100%"
        alignItems="flex-start"
        display="flex"
        top="0"
        marginTop="-80px"
        minHeight="100vh"
      >
        <Box width="100%">
          <ResetPasswordFormControlsBox>
            <form noValidate autoComplete="off">
              <Box fontSize={isPasswordResetMail ? 22 : 28} color="white" mb={4}>
                {isPasswordResetMail ? RESET_PASSWORD_FORCE_TITLE : RESET_PASSWORD_TITLE}
              </Box>
              <Box mt={2}>
                <TextField
                  id="email"
                  name="email"
                  value={this.state.email}
                  label="E-Mail Adresse"
                  onChange={this.handleEmailChange}
                  type="text"
                  onKeyUp={this.handleEmailOnEnter}
                  fullWidth={true}
                  InputLabelProps={{
                    style: { color: 'white' },
                  }}
                />
              </Box>
              {resetPasswordStore.error && this.displayError()}
              <Box mt={2}>
                <TransparentButton
                  variant="contained"
                  color="primary"
                  onClick={this.handleSubmit}
                  fullWidth={true}
                  disabled={resetPasswordStore.loading}
                  type="button"
                >
                  {resetPasswordStore.loading ? (
                    <CircularProgress
                      style={{ color: 'white', width: '24px', height: '24px' }}
                    />
                  ) : (
                    'Passwort zurücksetzen'
                  )}
                </TransparentButton>
              </Box>
            </form>
          </ResetPasswordFormControlsBox>
        </Box>
      </Box>
    );
  }

  private displayError() {
    return (
      <Box mt={2} color="#F44335" fontWeight="bold">
        E-Mail falsch
      </Box>
    );
  }

  private handleEmailOnEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === ENTER_KEY_CODE) {
      this.handleSubmit();
      e.preventDefault();
    }
  }

  private handleEmailChange = (e: ChangeEvent<HTMLInputElement>) =>
    this.setState({ email: e.target.value })

  private handleSubmit = async () => {
    try {
      await resetPasswordManager.resetPassword(this.state.email);
    } catch (err) {
      logger.error(`Could not send mail to [${this.state.email}] err=`, err);
    } finally {
      this.setState({
        email: '',
      });
    }
  }
}
