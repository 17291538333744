import { appStore } from '../app-store/store/AppStore';
import { UserModel } from '@ems/ems-lwl-models';
import { authClient } from '../../clients/auth-client/AuthClient';
import { loginStore } from './LoginStore';
import { FORBIDDEN_NON_LWL_USER_CODE, PASSWORD_TOO_SHORT_ERROR_CODE, UNATHORIZED_MESSAGE } from './Constants';
import { AxiosError } from 'axios';

class LoginUserManager {

  public async loginUser(login: string, password: string) {
    if (loginStore.loading) {
      return;
    }
    loginStore.clear();
    loginStore.loading = true;
    try {
      await authClient.login(login, password);
      await this.checkUserLoggedIn();
    } catch (err: unknown) {
      const status = (err as AxiosError)?.response?.status;
      if (status === FORBIDDEN_NON_LWL_USER_CODE) {
        loginStore.nonLwlUserError = true;
        throw err;
      }
      const errorCode = (err as AxiosError)?.response?.data?.code;
      const errorStatus = (err as AxiosError)?.response?.data?.status;
      if (errorStatus === UNATHORIZED_MESSAGE && errorCode === PASSWORD_TOO_SHORT_ERROR_CODE) {
        loginStore.setPasswordResetMail(login);
        throw err;
      }
      loginStore.error = true;
      throw err;
    } finally {
      loginStore.loading = false;
    }
  }

  public async logout(): Promise<void> {
    await authClient.logout();
  }

  public async checkUserLoggedIn() {
    try {
      const currentUser: UserModel = await authClient.getCurrentUserInfo();
      appStore.setCurrentUser(currentUser);
      appStore.setUserAuthorisation(true);
    } catch (error) {
      appStore.setUserAuthorisation(false);
      appStore.setCurrentUser(null);
      throw error;
    }
  }
}

export const loginUserManager = new LoginUserManager();
