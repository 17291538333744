import { observable, computed } from 'mobx';
import { StatusTranslation } from '@ems/ems-lwl-models';

class StatuesStore {

  @observable
  public statuses: StatusTranslation[] = [];
  @observable
  public areStatusesLoading = false;
  @computed
  public get areStatusesReady(): boolean {
    return !this.areStatusesLoading && this.statuses.length > 0;
  }
}

export const statusesStore = new StatuesStore();
