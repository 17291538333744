import socketClient from 'socket.io-client';

export class WebSocketClient {
  protected wsClient: SocketIOClient.Socket;

  constructor(location: string, path: string) {
    this.wsClient = socketClient(location, {
      path,
      autoConnect: false,
      reconnection: true,
      reconnectionAttempts: 20,
      reconnectionDelay: 1000,
      rejectUnauthorized: true,
    });
  }

  public async connect() {
    await this.wsClient.connect();
  }
  public async disconnect() {
    await this.wsClient.disconnect();
  }

  public onStartedReconnecting(callback: () => void) {
    this.wsClient.on('reconnecting', (attempt: number) => {
      if (attempt === 1) {
        callback();
      }
    });
  }

  public onReconnected(callback: () => void) {
    this.wsClient.on('reconnect', () => {
      callback();
    });
  }

  public onConnected(callback: () => void) {
    this.wsClient.on('connect', () => {
      callback();
    });
  }

  public onDisconnected(callback: () => void) {
    this.wsClient.on('disconnect', () => {
      callback();
    });
  }

  public onReconnectionFailed(callback: () => void) {
    this.wsClient.on('reconnect_failed', () => {
      callback();
    });
  }

}
