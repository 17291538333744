import { apiClient } from '../../clients/api-client/api.client';
import { statusesStore } from './store/StatusesStore';
import { logger } from '../logger/Logger';
import { notificationStore } from '../notification/NotificationStore';

class StatusesManager {

  public async fetchStatuses(): Promise<void> {
    try {
      statusesStore.areStatusesLoading = true;

      const translations = await apiClient.getAllStatusesTranslations();
      statusesStore.statuses = translations;
    } catch (err) {
      notificationStore.showError('Ein Fehler ist aufgetreten');
    } finally {
      statusesStore.areStatusesLoading = false;
    }
  }

  public translateStatus(value: string): string {
    const translation = statusesStore.statuses.find(s => s.id === value);
    if (translation) {
      return translation.label;
    }
    logger.warn('could not translate status', { value });
    return value;
  }

}

export const statusesManager = new StatusesManager();
