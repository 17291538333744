import { authClient } from '../../clients/auth-client/AuthClient';
import { restorePasswordStore } from './RestorePasswordStore';
import { notificationStore } from '../notification/NotificationStore';
import { logger } from '../logger/Logger';

class RestorePasswordManager {
  public async restorePassword(email: string, password: string, token: string): Promise<void> {
    if (restorePasswordStore.loading) {
      return;
    }
    restorePasswordStore.clear();
    restorePasswordStore.loading = true;
    try {
      await authClient.restorePassword(email, password, token);
      notificationStore.showSuccess('Ok');
    } catch (err) {
      logger.error(
        'An error occurred while restoring password',
      );
      restorePasswordStore.error = true;
      throw err;
    } finally {
      restorePasswordStore.loading = false;
    }
  }

  public async getSchema() {
    if (restorePasswordStore.loading) {
      return;
    }
    restorePasswordStore.clear();
    restorePasswordStore.loading = true;
    try {
      const response = await authClient.fetchScheme();
      return response.data;
    } catch (err) {
      logger.error(
        'An error occurred while fetching scheme',
      );
      restorePasswordStore.error = true;
      throw err;
    } finally {
      restorePasswordStore.loading = false;
    }
  }
}

export const restorePasswordManager = new RestorePasswordManager();
