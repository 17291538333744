import { observable, action, computed } from 'mobx';
import { Moment } from 'moment';
import { LeadQuery, LeadFilters, StatusValue, ExpiredStatus, LeadSortableColumn, SortDirection } from '@ems/ems-lwl-models';

class TableStore {
  @observable public currentPage: number = 1;
  @observable public rowsPerPage: number = 10;

  @observable public customerName: string = '';
  @observable public dealer: string = '';
  @observable public agDoc: string = '';
  @observable public expired: ExpiredStatus | null = null;
  @observable public createdFrom: Moment | null = null;
  @observable public createdTo: Moment | null = null;
  @observable public lastResult: StatusValue | null = null;
  @observable public cooperationPartner: string = '';
  @observable public desiredContactDateFrom: Moment | null = null;
  @observable public desiredContactDateTo: Moment | null = null;

  @observable public sortBy: LeadSortableColumn | null = null;
  @observable public sortDirection: SortDirection | null = null;

  @computed
  public get isFilterApplied() {
    return this.customerName !== '' ||
      this.dealer !== '' ||
      this.agDoc !== '' ||
      this.expired !== null ||
      this.createdFrom !== null ||
      this.createdTo !== null ||
      this.lastResult !== null ||
      this.cooperationPartner !== '' ||
      this.desiredContactDateFrom !== null ||
      this.desiredContactDateTo !== null;
  }

  @action
  public setRowsPerPage(rowsPerPage: number) {
    this.rowsPerPage = rowsPerPage;
    this.currentPage = 1;
  }

  @action
  public toggleSortBy(sortBy?: LeadSortableColumn) {
    if (!sortBy) {
      return;
    }
    const isTogglingTheSameSortField: boolean = this.sortBy === sortBy;

    if (isTogglingTheSameSortField) {
      switch (this.sortDirection) {
        case SortDirection.ASC:
          this.sortDirection = SortDirection.DESC;
          break;
        case SortDirection.DESC:
          this.sortDirection = null;
          this.sortBy = null;
          break;
      }
    } else {
      this.sortBy = sortBy;
      this.sortDirection = SortDirection.ASC;
    }
  }

  @computed
  public get leadQuery(): LeadQuery {
    const leadFilter: LeadFilters = {
      agDoc: this.agDoc,
      customerName: this.customerName,
      dealer: this.dealer,
      expired: this.expired,
      createdFrom: this.createdFrom?.toDate(),
      createdTo: this.createdTo?.toDate(),
      desiredContactDateFrom: this.desiredContactDateFrom?.toDate(),
      desiredContactDateTo: this.desiredContactDateTo?.toDate(),
      lastResult: this.lastResult,
      coopPartner: this.cooperationPartner,
    };

    const query: LeadQuery = {
      filters: leadFilter,
      page: this.currentPage,
      rowsPerPage: this.rowsPerPage,
      sortBy: this.sortBy ? this.sortBy : undefined,
      sortDirection: this.sortDirection ? this.sortDirection : undefined,
    };

    return query;
  }
}

export const tableStore = new TableStore();
