import * as React from 'react';
import {
    Box,
    Typography,
} from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

const FileWithPlusIcon: React.SFC = () => {
  return (<Box alignItems="center" display="flex">
        <InsertDriveFileIcon style={{ fontSize: 24, marginRight: '10px' }} />
        <Typography style={{
          position: 'absolute',
          top: '9px',
          left: '16px',
          color: '#1B000B',
          fontWeight: 'bolder',
        }}>+</Typography>
    </Box>);
};

export default FileWithPlusIcon;
