import React from 'react';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers';

const INVALID_DATE_FORMAT_MSG = 'Dieses Datum existiert nicht.';
const MIN_DATE_ERROR_MSG = 'Datum sollte nicht vor dem Mindestdatum liegen';
const MAX_DATE_ERROR_MSG = 'Datum sollte nicht nach dem maximalen Datum liegen';

export class DatePicker extends React.Component<KeyboardDatePickerProps> {
  public render() {

    const defaultProps: Partial<KeyboardDatePickerProps> = {
      disableToolbar: true,
      inputVariant: 'standard',
      variant: 'inline',
      format: 'DD.MM.YYYY',
      placeholder: 'DD.MM.YYYY',
      fullWidth: true,
      autoOk: true,
      invalidDateMessage: INVALID_DATE_FORMAT_MSG,
      minDateMessage: MIN_DATE_ERROR_MSG,
      maxDateMessage: MAX_DATE_ERROR_MSG,
    };

    const calculatedProps = {
      ...defaultProps,
      ...this.props,
    };

    return <KeyboardDatePicker
      {...calculatedProps}
    />;
  }
}
