import React from 'react';
import { ExpiredStatus, LeadModel } from '@ems/ems-lwl-models';
import ExpiredChip from './ExpiredChip';
import moment from 'moment';
import SubLabel from '../../components/SubLabel';
import { statusesManager } from '../statuses/StatusesManager';
import ChatIcon from '@material-ui/icons/Chat';
import { notePopupStore } from '../note/note-popup/NotePopupStore';

export class LeadTableCommons {

  public static customerRenderer = (row: LeadModel) => {
    return (
      <div>
        <div>
          <div>
            {`${LeadTableCommons.formatNull(row.customer.firstName)}`}
            {` ${LeadTableCommons.formatNull(row.customer.lastName)}`}
          </div>
        </div>
      </div>
    );
  }

  public static createdDateRenderer = (row: LeadModel) => {
    return (
      <div>
        <div>{LeadTableCommons.renderDate(row.createdAt)}</div>
        <SubLabel>{LeadTableCommons.renderTime(row.createdAt)}</SubLabel>
      </div>
    );
  }

  public static expiredRenderer = (row: LeadModel, disabled: boolean = false) => {

    const expiredStatus: ExpiredStatus = row.status.expiredStatus;

    return (
      <div>
        <ExpiredChip expiredStatus={expiredStatus} disabled={disabled} />
      </div>
    );
  }

  public static lastStatusRenderer = (row: LeadModel) => {
    return (
      <div>{statusesManager.translateStatus(row.status.lastStatus)}</div>
    );
  }

  public static dealerRenderer = (row: LeadModel) => {
    const dealerName = LeadTableCommons.dealerNameRenderer(row);
    return (
      <div>
        <div>{dealerName}</div>
        <SubLabel>{row.dealer.organization}</SubLabel>
      </div>
    );
  }

  private static dealerNameRenderer(row: LeadModel) {
    if (row.dealer.firstName === null && row.dealer.lastName === null) {
      return '-';
    }

    const firstName = LeadTableCommons.formatNull(row.dealer.firstName);
    const lastName = LeadTableCommons.formatNull(row.dealer.lastName);

    if (firstName === '' && lastName === '') {
      return '-';
    }
    return `${firstName} ${lastName}`;
  }

  public static formatNull = (value: string | null): string => {
    if (value) {
      return value;
    }
    return '';
  }

  public static renderDate(date: Date): string {
    return moment(date).format('DD.MM.YYYY');
  }

  public static renderTime(date: Date): string {
    return moment(date).format('HH:mm');
  }

  public static renderNoteButton(lead: LeadModel): JSX.Element {
    return (
      <ChatIcon
        cursor="pointer"
        style={{ color: 'black', fontSize: '16px' }}
        onClick={() => notePopupStore.showNotePopup(lead)}
      />
    );
  }

  public lastStatusRenderer = (row: LeadModel) => {
    return (
      <div>{statusesManager.translateStatus(row.status.lastStatus)}</div>
    );
  }
}
