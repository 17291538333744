import React, { Component } from 'react';
import { Container, Box } from '@material-ui/core';
import { RestorePasswordForm } from '../modules/restore-password/RestorePasswordForm';
import { observer } from 'mobx-react';
import { Notification } from '../modules/notification/Notification';

interface RestorePasswordProps {
  token: string;
}
@observer
export class RestorePassword extends Component<RestorePasswordProps, {}> {

  public render() {
    return (
        <Box bgcolor="black">
        <Box style={{
          backgroundImage: 'url(/img/LwL-Login_PS-Blurred.png)',
          backgroundSize: '1440px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top center',
        }}>
          <Box style={{
            background: 'linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0.4), rgba(0,0,0,1))',
            backgroundSize: '1440px',
            backgroundPosition: 'top center',
          }}>
          <Container style={{ maxWidth: '1440px' }}>
            <Notification />
            <RestorePasswordForm token={this.props.token}/>
          </Container>
          </Box>
        </Box>
        </Box>
    );
  }
}
