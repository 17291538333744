import { action, observable } from 'mobx';

class PopupStore {
  @observable public popupVisible: string | null = null;

  @action
  public requestOpen(popupName: string) {
    if (this.popupVisible === null) {
      this.popupVisible = popupName;
    }
  }

  @action
  public requestClose(popupName: string) {
    if (this.popupVisible === popupName) {
      this.popupVisible = null;
    }
  }
}

export const popupStore = new PopupStore();
