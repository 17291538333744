import React from 'react';
import { observer } from 'mobx-react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { notificationStore } from './NotificationStore';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

@observer
export class Notification extends React.Component<{}> {

  public render() {
    if (notificationStore.message === null) {
      return null;
    }

    const { message, severity } = notificationStore.message;
    return (
        <Snackbar open={notificationStore.isVisible}>
          <Alert onClose={() => notificationStore.hideNotification()}
            severity={severity}>
            {message}
          </Alert>
        </Snackbar>
    );
  }
}
