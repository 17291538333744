import { observable, computed } from 'mobx';
import { CoopPartner } from '@ems/ems-lwl-models';

class CoopPartnerStore {

  @observable
  public partners: CoopPartner[] = [];
  @observable
  public arePartnersLoading = false;
  @computed
  public get arePartnersReady(): boolean {
    return !this.arePartnersLoading && this.partners.length > 0;
  }
}

export const coopPartnerStore = new CoopPartnerStore();
