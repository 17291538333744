import React from 'react';
import {
  Grid,
  Dialog,
  DialogContent,
  IconButton,
  DialogContentText,
  Box,
  DialogActions,
  Button,
  styled,
  CircularProgress,
  DialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DatePicker } from '../../components/DatePicker';
import { TimePicker } from '../../components/TimePicker';
import { terminPopupStore } from './TerminPopupStore';
import { observer } from 'mobx-react';
import { terminService } from './TerminService';

const TerminDialogContent = styled(DialogContent)({
  maxWidth: 450,
});

@observer
export class TerminPopup extends React.Component {
  public render() {
    return <Dialog
        open={terminPopupStore.isDialogOpened()}
        onClose={() => terminService.discardTermin()}
        scroll="body">
      {this.renderHeader()}
      <TerminDialogContent>
        <DialogContentText id="alert-dialog-description">
          <Box component="span" display="block">
            Wählen Sie den Tag und die Uhrzeit aus,
            bis zu der der nächste Anruf getätigt werden soll.
          </Box>
        </DialogContentText>
        <Box>
          <Grid container>
            <Grid item xs={6}>
              <Box m={1.5}>
              <DatePicker
                  id="dueDate"
                  name="DueDate"
                  value={terminPopupStore.dueDate}
                  label="Tag"
                  onChange={(arg) => terminPopupStore.updateDueDate(arg?.toDate())}
                  onError={(error) => {
                    terminPopupStore.dueDateValid = !error;
                  }}
              />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box m={1.5}>
              <TimePicker
                  label="Zeit"
                  value={terminPopupStore.dueDateTime}
                  onChange={(arg) => terminPopupStore.updateDueDateTime(arg?.toDate())}
                  onError={(error) => {
                    terminPopupStore.dueDateTimeValid = !error;
                  }}
              />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </TerminDialogContent>
      <DialogActions style={{ marginTop: 20 }}>
        <Button onClick={() => terminService.discardTermin()}
                variant="outlined" color="primary" style={{ minWidth: 150 }}>
          Zurück
        </Button>
        <Button onClick={() => terminService.updateTermin()}
                disabled={!terminPopupStore.isValid() || terminPopupStore.isSaving}
                variant="contained" color="primary" autoFocus style={{ minWidth: 150 }}>
          {terminPopupStore.isSaving
              ? <CircularProgress style={{ width: 24, height: 24, color: 'primary' }}/>
              : 'Speichern'
          }
        </Button>
      </DialogActions>
    </Dialog>;
  }

  private renderHeader = () => {
    return (
        <DialogTitle>
          <IconButton aria-label="close" onClick={() => terminService.discardTermin()}
                      style={{ position: 'absolute', top: '10px', right: '10px' }}>
            <CloseIcon/>
          </IconButton>
          <DialogContentText>
            <Box fontWeight="fontWeightBold" m={1} component="span" display="block">
              Termin Korrigieren
            </Box>
          </DialogContentText>
        </DialogTitle>
    );
  }
}
