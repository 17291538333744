class CookieHelper {
  public getValueByName(name: string): string {
    try {
      const spliced = document.cookie.split(';').map(e => e.split('='));
      const cookie = spliced.find(([key]) => key.trim() === name);
      if (!cookie) {
        return '';
      }
      return cookie[1].trim();
    } catch (err) {
      return '';
    }
  }
}

export const cookieHelper = new CookieHelper();
