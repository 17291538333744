import { apiClient } from '../../clients/api-client/api.client';
import { leadLockStore } from './LeadLockStore';
import { LeadModel, isLwlError } from '@ems/ems-lwl-models';
import { leadManager } from '../leads/LeadManager';
import { myLeadsManager } from '../leads/MyLeadsManager';
import { ExpiredStatus } from '@ems/ems-lwl-models/dist';
import { notificationStore } from '../notification/NotificationStore';
import { appStore } from '../app-store/store/AppStore';
import { AxiosError } from 'axios';

export class LeadLockManager {
  public async lockLead(lead: LeadModel) {
    const isAlreadyLocked = this.isLocked(lead);
    if (isAlreadyLocked) {
      return;
    }
    const isLockingInProgress = this.isLockInProgress(lead);
    if (isLockingInProgress) {
      return;
    }
    leadLockStore.lockingInProgress.push(lead.id);

    try {
      await apiClient.lockLead(lead.id);
      await leadManager.fetchLeads(false);
      await myLeadsManager.fetchMyLeads(false);

      notificationStore.showSuccess('Erfolg');
    } catch (err: unknown) {
      this.handleLockLeadException((err as AxiosError)?.response?.data);
    } finally {
      leadLockStore.lockingInProgress.remove(lead.id);
    }
  }

  public async unlockLead(lead: LeadModel) {
    const isAlreadyUnlocked = !this.isLocked(lead);
    if (isAlreadyUnlocked) {
      return;
    }
    const isUnlockingInProgress = this.isLockInProgress(lead);
    if (isUnlockingInProgress) {
      return;
    }
    leadLockStore.lockingInProgress.push(lead.id);

    try {
      await apiClient.unlockLead(lead.id);
      await leadManager.fetchLeads(false);
      await myLeadsManager.fetchMyLeads(false);
    } catch (err) {
      notificationStore.showError('Ein Fehler ist aufgetreten');
    } finally {
      leadLockStore.lockingInProgress.remove(lead.id);
    }
  }

  public isLockedByMe(lead: LeadModel) {
    return lead.lockedBy?.id === appStore.currentUser?.id;
  }

  public isLocked(lead: LeadModel) {
    return !!lead.lockedBy;
  }

  public isLockInProgress(lead: LeadModel) {
    return leadLockStore.lockingInProgress.includes(lead.id);
  }

  public shouldLockBeConfirmed(lead: LeadModel): boolean {
    const expiredStatus: ExpiredStatus = lead.status.expiredStatus;

    return expiredStatus === ExpiredStatus.FINISHED
      || expiredStatus === ExpiredStatus.NO
      || expiredStatus === ExpiredStatus.NO_WITH_TERMIN;
  }

  private handleLockLeadException(err: Error) {
    if (isLwlError(err)) {
      switch (err.errorCode) {
        case 'LWL_ERROR_LOCK_LIMIT_EXCEEDED':
          notificationStore
            .showError('Sie können nur bis zu 5 Leads gleichzeitig bearbeiten');
          break;
        case 'LWL_ERROR_LEAD_ALREADY_LOCKED':
          notificationStore.showError('Der Lead wurde bereits gesperr');
          break;
        default:
          notificationStore.showError('Ein Fehler ist aufgetreten');
      }
    } else {
      notificationStore.showError('Ein Fehler ist aufgetreten');
    }
  }
}

export const leadLockManager = new LeadLockManager();
