import { observable } from 'mobx';
import { LeadModel } from '@ems/ems-lwl-models';
import { popupStore } from '../popup/PopupStore';

const popupName = 'leadSuccessModal';

class LeadSuccessModalStore {

  @observable public lead: LeadModel | null = null;

  public isDialogOpened() {
    return popupStore.popupVisible === popupName;
  }

  public showModal(lead: LeadModel) {
    this.lead = lead;
    popupStore.requestOpen(popupName);
  }

  public closeModal() {
    popupStore.requestClose(popupName);
  }
}

export const leadSuccessModalStore = new LeadSuccessModalStore();
