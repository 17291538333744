import React, { Component } from 'react';
import { Container, Box } from '@material-ui/core';
import { LoginForm } from '../modules/login/LoginForm';
import { observer } from 'mobx-react';

@observer
export class Login extends Component {

  public render() {
    return (
        <Box bgcolor="black">
        <Box style={{
          backgroundImage: 'url(/img/LwL-Login_PS-Blurred.png)',
          backgroundSize: '1440px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top center',
        }}>
          <Box style={{
            background: 'linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0.4), rgba(0,0,0,1))',
            backgroundSize: '1440px',
            backgroundPosition: 'top center',
          }}>
          <Container style={{ maxWidth: '1440px' }}>
            <LoginForm />
          </Container>
          </Box>
        </Box>
        </Box>
    );
  }
}
