import { ThemeOptions } from '@material-ui/core';

const BUTTON_COLOR = '#8E0038';

export const datepickerThemeOptions: ThemeOptions = {
  overrides: {
    MuiInputAdornment: {
      positionEnd: {
        marginLeft: '0px',
      },
      root: {
        '& > button': {
          padding: '5px',
          backgroundColor: BUTTON_COLOR,
          borderRadius: '0px',
          color: 'white',
        },
        '& > button:hover': {
          backgroundColor: BUTTON_COLOR,
        },
        MuiButtonBase: {
          root: {
            padding: '0px',
          },
        },
      },
    },
    MuiPaper: {
      root: {
        '& > MuiPickersBasePicker-pickerView': {
          backgroundColor: 'red',
        },
      },
    },
  },
};
