import { action, computed, observable } from 'mobx';
import { Color } from '@material-ui/lab/Alert';

interface NotificationMessage {
  message: string;
  severity: Color;
}

class NotificationStore {

  @observable public message: NotificationMessage | null = null;

  @action
  public hideNotification() {
    this.message = null;
  }

  @action
  public showError(message: string) {
    this.message = {
      message,
      severity: 'error',
    };
  }

  @action
  public showSuccess(message: string) {
    this.message = {
      message,
      severity: 'success',
    };
  }

  @computed
  public get isVisible() {
    return this.message !== null;
  }
}

export const notificationStore = new NotificationStore();
