import React from 'react';
import { TablePagination, IconButton, TablePaginationProps, makeStyles, Theme, createStyles, LabelDisplayedRowsArgs } from '@material-ui/core';
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const createPaginationStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }),
);

export class LeadsTablePagination extends React.Component<TablePaginationProps> {
  public render() {
    return <TablePagination
      ActionsComponent={this.renderPagination}
      rowsPerPageOptions={[5, 10, 25, 50]}
      component="div"
      count={this.props.count}
      rowsPerPage={this.props.rowsPerPage}
      page={this.props.page}
      onChangeRowsPerPage={this.props.onChangeRowsPerPage}
      labelRowsPerPage="Zeilen pro Seite"
      labelDisplayedRows={this.paginationLocalisedText}
      onPageChange={this.props.onPageChange}
    />;
  }

  private renderPagination(
    props: TablePaginationActionsProps,
  ) {
    const { onPageChange, page, count, rowsPerPage } = props;
    const lastPage = Math.ceil(count / rowsPerPage) - 1;
    const style = createPaginationStyles();

    return <div className={style.root}>
      <IconButton
        onClick={(event) => onPageChange(event, 0)}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={(event) => onPageChange(event, page - 1)}
        disabled={page === 0} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={(event) => onPageChange(event, page + 1)}
        disabled={page >= lastPage}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={(event) => onPageChange(event, lastPage)}
        disabled={page >= lastPage}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>;
  }

  private paginationLocalisedText = ({ from, to, count }: LabelDisplayedRowsArgs) => {
    return `${from}-${to === -1 ? count : to} von ${count !== -1 ? count : to}`;
  }
}
