import { OfferModel } from '@ems/ems-lwl-models';
import { coopPartnerStore } from '../coop-partners/store/CoopPartnerStore';
import { logger } from '../logger/Logger';

export class OfferRedirectionService {
  public getRedirectUrl(offer: OfferModel) {
    const partner = coopPartnerStore.partners.find(p => p.value === offer.organization);
    if (!partner) {
      logger.warn('Organization was not found: ' + offer.organization);
      return '#';
    }
    const appUrl = partner.url;
    return this.getRedirectionUrl(appUrl, offer.id);
  }

  private getRedirectionUrl(appUrl: string, offerId: string) {
    return `${appUrl}/insuranceoffer/${offerId}`;
  }
}

export const offerRedirectionService = new OfferRedirectionService();
