class Config {

  get host(): string {
    return `${window.location.protocol}//${window.location.hostname}`;
  }

  get apiUrl(): string {
    return `${this.host}/api`;
  }

}

export const config = new Config();
