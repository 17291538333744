import {
  CoopPartner,
  DealershipModel,
  DealershipQuery,
  ILeadUpdateModel,
  isLwlError,
  LeadModel,
  LeadModelResponse,
  LeadQuery,
  Note,
  StatusTranslation,
  UserModel,
  UserQuery,
  SalesChannel,
} from '@ems/ems-lwl-models';
import { QueryString } from '@nova/uri';
import { WebRestClient } from '@nova/web-rest-client';
import { config } from '../../config';
import { logger } from '../../modules/logger/Logger';
import { AxiosError } from 'axios';

class ApiClient extends WebRestClient {
  constructor() {
    super(config.apiUrl);
  }

  public async getLeads({
    filters,
    page = 1,
    rowsPerPage,
    sortBy,
    sortDirection,
  }: LeadQuery): Promise<LeadModelResponse> {
    const queryString = QueryString.toString(filters, {
      page,
      rowsPerPage,
      sortBy,
      sortDirection,
    });
    const response = await this.httpClient.get<LeadModelResponse>(
      '/leads' + queryString,
    );
    return response.data;
  }

  public async getMyLeads(): Promise<LeadModel[]> {
    const response = await this.httpClient.get<LeadModel[]>('/my-leads');
    return response.data;
  }

  public async getLead(id: number): Promise<LeadModel> {
    const response = await this.httpClient.get<LeadModel>(`/leads/${id}`);
    return response.data;
  }

  public async lockLead(leadId: number) {
    await this.httpClient.post<void>(`/leads/${leadId}/lock`);
  }

  public async unlockLead(leadId: number) {
    await this.httpClient.post<void>(`/leads/${leadId}/unlock`);
  }

  public async getAllStatusesTranslations(): Promise<StatusTranslation[]> {
    const resp = await this.httpClient.get<StatusTranslation[]>('/statuses');
    return resp.data;
  }

  public async getAllCoopPartners(): Promise<CoopPartner[]> {
    const resp = await this.httpClient.get<CoopPartner[]>('/coop-partners');
    return resp.data;
  }

  public async getNoteTextForLead(leadId: number): Promise<string> {
    try {
      const resp = await this.httpClient.get<Note>(`/leads/${leadId}/note`);
      return resp.data.text;
    } catch (err: unknown) {
      const innerError = (err as AxiosError)?.response?.data;
      if (
        innerError &&
        isLwlError(innerError) &&
        innerError.httpCode === 404 &&
        innerError.errorCode === 'LWL_ERROR_NOT_FOUND'
      ) {
        logger.log(
          `There was no Note for lead [${leadId}]. Returning empty string.`,
        );
        return '';
      }
      throw err;
    }
  }

  public async addOrUpdateNoteTextForLead(leadId: number, text: string): Promise<void> {
    await this.httpClient.post(`/leads/${leadId}/note`, { text });
  }

  public async updateTermin(leadId: number, termin: Date): Promise<void> {
    await this.httpClient.put(`/leads/${leadId}/termin`, { termin });
  }

  public async updateLead(leadId: number, payload: ILeadUpdateModel): Promise<void> {
    await this.httpClient.put(`/leads/${leadId}`, payload);
  }

  public async getDealerUsers(query: UserQuery): Promise<UserModel[]> {
    const queryString = QueryString.toString(query);
    const response = await this.httpClient.get<UserModel[]>(
      `/users/dealers${queryString}`,
    );
    return response.data;
  }

  public async getDealerships(query: DealershipQuery): Promise<DealershipModel[]> {
    const queryString = QueryString.toString(query);
    const response = await this.httpClient.get<DealershipModel[]>(
      `/dealerships${queryString}`,
    );
    return response.data;
  }

  public async getAllSalesChannels(): Promise<SalesChannel[]> {
    const response = await this.httpClient.get<SalesChannel[]>('/sales-channels');
    return response.data;
  }
}

export const apiClient = new ApiClient();
(window as any).apiClient = apiClient; // TODO
