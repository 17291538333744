import React from 'react';
import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { Main } from '../pages/Main';
import { NotFound } from '../pages/NotFound';
import { AccountDetails } from '../pages/AccountDetails';
import { ResetPassword } from '../pages/ResetPassword';
import { RestorePassword } from '../pages/RestorePassword';

interface MatchParams {
  token: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {
}

export const Routes = () => (
  <Switch>
    <Route exact path="/" component={Main} />
    <Route exact path="/account-details" component={AccountDetails} />
    <Route exact path="/reset-password" component={ResetPassword} />
    <Route
      exact
      path="/restorepassword/:token"
      render={({ match }: MatchProps) => (
        <RestorePassword token={match.params.token} />
      )}
    />
    <Route exact path="*" component={NotFound} />
  </Switch>
);
