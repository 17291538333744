import { apiClient } from '../../clients/api-client/api.client';
import { logger } from '../logger/Logger';
import { notificationStore } from '../notification/NotificationStore';
import { leadUpdateModalStore } from './LeadUpdateModalStore';
import moment from 'moment';
import { LeadModel, StatusValue } from '@ems/ems-lwl-models';
import { leadSuccessModalStore } from '../lead-success/LeadSuccessModalStore';

class LeadUpdateService {

  public async saveChanges() {
    if (!leadUpdateModalStore.isValid()) {
      return;
    }

    const lead = leadUpdateModalStore.editedLead;
    if (!lead) {
      leadUpdateModalStore.closeModal();
      return;
    }

    const terminDate = leadUpdateModalStore.dueDate;
    const terminTime = leadUpdateModalStore.dueDateTime;
    const newStatus = leadUpdateModalStore.status;
    const newNote = leadUpdateModalStore.note;

    if (!terminDate || !terminTime) {
      notificationStore.showError('Beim Aktualisieren des Leads ist ein Fehler aufgetreten');
      return;
    }
    if (!newStatus) {
      notificationStore.showError('Beim Aktualisieren des Leads ist ein Fehler aufgetreten');
      return;
    }

    const dueDateTime = moment(terminTime);
    const dueDate = moment(terminDate)
      .hour(dueDateTime.hour())
      .minute(dueDateTime.minute())
      .toDate();

    try {
      leadUpdateModalStore.isSaving = true;

      await apiClient.updateLead(lead.id, {
        status: newStatus,
        noteText: newNote,
        termin: dueDate,
      });
      leadUpdateModalStore.closeModal();
    } catch (err) {
      logger.error(err);
      notificationStore.showError('Beim Aktualisieren des Leads ist ein Fehler aufgetreten');
    } finally {
      leadUpdateModalStore.isSaving = false;
    }
  }

  public async discardChanges() {
    leadUpdateModalStore.closeModal();
  }

  /**
   * Fired up when lead changes.
   *
   * Open a LeadSuccessModal when a lead is 'finished'
   * and a LeadUpdateModal is open for this lead.
   */
  public async onLeadChanged(changedLeadId: number) {
    try {
      if (!leadUpdateModalStore.isDialogOpened()) {
        return;
      }

      if (changedLeadId !== leadUpdateModalStore.editedLead?.id) {
        return;
      }

      const updatedLead = await apiClient.getLead(changedLeadId);
      if (updatedLead.status.lastStatus === StatusValue.finished) {
        this.showSuccessModal(leadUpdateModalStore.editedLead);
      }
    } catch (err) {
      logger.error('error on lead changed', err);
    }
  }

  private async showSuccessModal(lead: LeadModel) {
    leadUpdateModalStore.closeModal();
    leadSuccessModalStore.showModal(lead);
  }

}

export const leadUpdateService = new LeadUpdateService();
