import { action, computed, observable } from 'mobx';
import { statusesStore } from '../../statuses/store/StatusesStore';
import { coopPartnerStore } from '../../coop-partners/store/CoopPartnerStore';
import { UserModel } from '@ems/ems-lwl-models';

class AppStore {

  @observable public currentUser: UserModel | null = null;
  @observable public isUserAuthorised: boolean | null = null;

  @computed
  public get isAppReady() {
    return statusesStore.areStatusesReady && coopPartnerStore.arePartnersReady;
  }

  @action
  public setCurrentUser(user: UserModel | null) {
    this.currentUser = user;
  }

  @action
  public setUserAuthorisation(isUserAuthorised: boolean) {
    this.isUserAuthorised = isUserAuthorised;
  }

}

export const appStore = new AppStore();
