import { leadWSClient } from '../../clients/ws-client/lead.client';
import { logger } from '../logger/Logger';
import { liveUpdateStore } from './LiveUpdateStore';
import { leadManager } from '../leads/LeadManager';
import { myLeadsManager } from '../leads/MyLeadsManager';
import { leadUpdateService } from '../lead-update/LeadUpdateService';

class LiveUpdateService {

  constructor() {
    this.registerListeners();
  }

  public async start() {
    await leadWSClient.connect();
  }
  public async stop() {
    await leadWSClient.disconnect();
  }

  private async registerListeners() {
    leadWSClient.listenOnLeadChanged(this.onLeadChanged);
    leadWSClient.listenOnLeadCreated(this.onLeadCreated);

    leadWSClient.onConnected(() => {
      logger.log('Socket connected');
    });
    leadWSClient.onDisconnected(() => {
      logger.error('Socket disconnected');
    });
    leadWSClient.onReconnected(() => {
      logger.log('Socket reconnected');
      liveUpdateStore.isReconnecting = false;
    });
    leadWSClient.onStartedReconnecting(() => {
      logger.warn('Socket started reconnecting...');
      liveUpdateStore.isReconnecting = true;
    });
    leadWSClient.onReconnectionFailed(() => {
      logger.error('Socket reconnection failed');
      liveUpdateStore.isDisconnected = true;
    });
  }

  private onLeadChanged = async (leadId: number) => {
    logger.log(`Lead changed [${leadId}]!`);
    leadUpdateService.onLeadChanged(leadId);
    leadManager.fetchLeads(false);
    myLeadsManager.fetchMyLeads(false);
  }

  private onLeadCreated = async (leadId: number) => {
    logger.log(`Lead created [${leadId}]!`);
    leadManager.fetchLeads(false);
  }
}

export const liveUpdateService = new LiveUpdateService();
