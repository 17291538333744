import React, { FunctionComponent } from 'react';
import { ExpiredStatus } from '@ems/ems-lwl-models';
import { ExpiredStatusService } from './ExpiredStatusService';
import { Chip } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

interface ExpiredChipProps {
  expiredStatus: ExpiredStatus;
  disabled?: boolean | undefined;
}

const ExpiredChip: FunctionComponent<ExpiredChipProps> = ({ expiredStatus, disabled }) => {
  const expiredStatusLabel: string = ExpiredStatusService.getLabel(expiredStatus);

  if (disabled) {
    return <GrayChip label={expiredStatusLabel} variant="outlined" />;
  }

  switch (expiredStatus) {
    case ExpiredStatus.YES:
    case ExpiredStatus.YES_WITH_TERMIN:
      return <OrangeChip label={expiredStatusLabel} variant="outlined" />;

    case ExpiredStatus.NO:
    case ExpiredStatus.NO_WITH_TERMIN:
      return <BlueChip label={expiredStatusLabel} variant="outlined" />;

    case ExpiredStatus.FINISHED:
      return <GrayChip label={expiredStatusLabel} variant="outlined" />;

    case ExpiredStatus.UNKNOWN:
      return <></>;
  }
};
export default ExpiredChip;

const OrangeChip = withStyles({
  root: {
    color: '#C27D27',
    borderColor: '#C27D27',
    backgroundColor: '#FFF3E5',
  },
})(Chip);

const BlueChip = withStyles({
  root: {
    color: '#4885C2',
    borderColor: '#4885C2',
    backgroundColor: '#E5F2FF',
  },
})(Chip);

const GrayChip = withStyles({
  root: {
    color: '#979797',
    borderColor: '#979797',
    backgroundColor: '#EDEDED',
  },
})(Chip);
