import { WebSocketClient } from './ws.client';
import { logger } from '../../modules/logger/Logger';

export class LeadWSClient extends WebSocketClient {
  constructor() {
    super(window.location.href, '/api/socket.io');
  }

  private readonly leadChangedEvent = 'lead-changed';
  private readonly leadCreatedEvent = 'lead-created';

  public listenOnLeadChanged(callback: (id: number) => void) {
    if (this.wsClient.hasListeners(this.leadChangedEvent)) {
      return;
    }
    logger.log(`Registering listener [${this.leadChangedEvent}]`);
    this.wsClient.on(this.leadChangedEvent, (leadId: string) => {
      const id = Number(leadId);
      if (!Number.isInteger(id)) {
        logger.error('Received lead-changed msg with wrong id', leadId);
        return;
      }
      try {
        callback(id);
      } catch (err) {
        logger.error('Callback for lead-changed msg failed', err);
      }
    });
  }

  public listenOnLeadCreated(callback: (id: number) => void) {
    if (this.wsClient.hasListeners(this.leadCreatedEvent)) {
      return;
    }
    logger.log(`Registering listener [${this.leadCreatedEvent}]`);
    this.wsClient.on(this.leadCreatedEvent, (leadId: string) => {
      const id = Number(leadId);
      if (!Number.isInteger(id)) {
        logger.error('Received lead-changed msg with wrong id', leadId);
        return;
      }
      try {
        callback(id);
      } catch (err) {
        logger.error('Callback for lead-changed msg failed', err);
      }
    });
  }
}

export const leadWSClient = new LeadWSClient();
